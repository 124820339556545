import {useEffect, useState, useRef} from 'react';
import { Layout, Button, Card, Form, 
    Loading, Notification, Dropdown, MessageBox
} from 'element-react';
import { useTranslation } from 'react-i18next';
import { Link, useParams, useHistory } from "react-router-dom";
import Summary from '../components/order/Summary';
import SellerInfo from '../components/order/SellerInfo';
import BuyerInfo from '../components/order/BuyerInfo';
import ProductInfo from '../components/order/ProductInfo';
import { getOrder, listUsers, listCounterInfoTables } from '../graphql/queries';
import { updateOrder, updateCounterInfoTable } from '../graphql/mutations';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import TermsSection from '../components/order/TermsSection';
import ShippingSection from '../components/order/ShippingSection';
import FeeSection from '../components/order/FeeSection';
import PaymentSection from '../components/order/PaymentSection';
import { formatDate, stringToDate } from '../utils/FormatUtils';
import { generalFormRules } from '../utils/OrderFormRules';
import { cloneOrder, sellerStatus } from '../services/OrderService';
import HistorySection from '../components/order/HistorySection';
import sendEmailTemplate from '../graphql/lambdaInvokes';
import * as Constans from '../../src/utils/Constants';
import { pad } from '../utils/FormatUtils';
import WarningModal from '../components/WarningModal';
import PagerQuery from '../utils/PagerQuery';

function OrderWizard(){
    const [warningFinishModal, showWarningFinishModal] = useState(false);
    const [warningCancelModal, showWarningCancelModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const form1 = useRef(null);
    const buyerEmailRef = useRef();
    const [formInputs, setFormInputs] = useState({
        name : '',
        orderNumber: 0,
        companyName: '',
        products: [],
        fees: [],
        payments: [],
        sellerStatus: 0,
        total: 0,
        buyerName : '',
        buyerPhone : ''
    });
    const [rules1, setRules1] = useState(null);
    let { id } = useParams();

    useEffect(() => {
        console.log('OrderWizard');
        //setFormInputs({...formInputs, orderNumber: id })
        loadOrder();
        setRules1(generalFormRules(t));
    },[t]);

    const loadOrder = async() =>{
        setLoading(true);
        console.log('loadOrder')
        try{
            let input = {
                id: id
            }
            const result  = await API.graphql({
                query: getOrder,
                variables: input
              });
            console.log("================ ORDER ================")
            console.log({result})
            const _odersDB = result.data.getOrder;

            let products = [];
            _odersDB.products?.forEach((element, index) => { 
                products.push({
                    index: index,
                    product: {
                        name: element.name,
                        id: element.id,
                        photo: [element.photo]
                    },
                    quantity: element.quantity,
                    unit: element.unit,
                    unitPrice: element.unitPrice,
                    subtotal: element.subtotalPrice,
                    description: element.comments
                })
            });

            let fees = [];
            _odersDB.additionalFees?.forEach((element, index) => { 
                fees.push({
                    quantity: element.quantity,
                    unit: element.unit,
                    unitPrice: element.unitPrice,
                    subtotal: element.subtotal,
                    fee: element.fee,
                    description: element.description
                })
            });
            
            let payments = [];
            _odersDB.payments?.forEach((element, index) => { 
                payments.push({
                    date: stringToDate(element.date),
                    amount: element.amount,
                    method: element.method,
                    notes: element.notes,
                })
            });

            let history = [];
            _odersDB.history?.forEach((element, index) => { 
                history.push({
                    date: stringToDate(element.date),
                    seller_status: element.seller_status,
                    buyer_status: element.buyer_status,
                    process_status: element.process_status,
                    type: element.type,
                    note: element.note
                })
            });

            setFormInputs({
                id: id,
                name : '',
                sellerStatus : _odersDB.seller_status,
                buyerStatus : _odersDB.buyer_status,
                trackingNumber: _odersDB.tracking_number,
                orderNumber : _odersDB.orderNumber,
                companyName : _odersDB.company_name,
                companyContactName: _odersDB.company_contact_name,
                companyEmail: _odersDB.company_email,
                companyAddress: _odersDB.company_address,
                //3. BuyerInfo
                buyerEmail: _odersDB.buyer_email ? _odersDB.buyer_email : '',
                buyer: '',
                buyerName : _odersDB.buyer_name ? _odersDB.buyer_name : '',
                buyerPhone : _odersDB.buyer_phone ? _odersDB.buyer_phone : '',
                street: _odersDB.billingAddress?.street,
                city: _odersDB.billingAddress?.city,
                state: _odersDB.billingAddress?.state,
                country: _odersDB.billingAddress?.country,
                zipCode: _odersDB.billingAddress?.zipCode,
                products: products,
                currency: _odersDB.terms?.currency ? _odersDB.terms?.currency : '' ,
                paymentMethod: _odersDB.terms?.paymentMethod,
                deliveryDate: stringToDate(_odersDB.terms?.deliveryDate),
                guarantee: _odersDB.terms?.guarantee,
                incoterm: _odersDB.terms?.incoterm,
                //6. Shipping
                shippingMethod: _odersDB.shipping?.method,
                paymentCost: _odersDB.shipping?.cost,
                shippingStatus: _odersDB.shipping?.status,
                originStreet: _odersDB.shipping?.originAddress.street,
                originCity: _odersDB.shipping?.originAddress.city,
                originState: _odersDB.shipping?.originAddress.state,
                originCountry: _odersDB.shipping?.originAddress.country,
                originZip: _odersDB.shipping?.originAddress.zipCode,
                originPhone: _odersDB.shipping?.originAddress.phone,
                originContact: _odersDB.shipping?.originAddress.contact,
                destinationStreet: _odersDB.shipping?.destinationAddress.street,
                destinationCity: _odersDB.shipping?.destinationAddress.city,
                destinationState: _odersDB.shipping?.destinationAddress.state,
                destinationCountry: _odersDB.shipping?.destinationAddress.country,
                destinationZip: _odersDB.shipping?.destinationAddress.zipCode,
                destinationPhone: _odersDB.shipping?.destinationAddress.phone,
                destinationContact: _odersDB.shipping?.destinationAddress.contact,
                shippingSpecialInfo: _odersDB.shipping?.specialInstructions,
                //7. Fees
                fees: fees,
                //8. Payments
                payments: payments,
                total: _odersDB.total,
                history: history
            })
            setLoading(false);
        }catch(e){
            setLoading(false);
            console.error(e);
        }
    }

    const updateOrderFromForm = async (sellerStatus, buyerStatus, historyParam) =>{
        console.log('updating order');
        console.log(`buyerStatus :: ${buyerStatus}`);
        console.log(`historyParam :: ${historyParam}`);
        setLoading(true);
        try{
            let billingAddress = {
                street: formInputs.street,
                city: formInputs.city,
                state: formInputs.state,
                country: formInputs.country,
                zipCode: formInputs.zipCode
            }
    
            let products = [];
            formInputs.products.forEach((element, index) => {
                products.push({
                    name: element.product.name,
                    id: element.product.id,
                    quantity: element.quantity,
                    unit: element.unit,
                    unitPrice: element.unitPrice,
                    subtotalPrice: element.subtotal,
                    comments: element.description,
                    photo: element.product.photo ? element.product.photo[0] : null
                })
            })

            let fees = [];
            formInputs.fees.forEach((element, index) => {
                fees.push({
                    quantity: element.quantity,
                    unit: element.unit,
                    unitPrice: element.unitPrice,
                    subtotal: element.subtotal,
                    description: element.description,
                    fee: element.fee
                })
            });

            let payments = [];
            formInputs.payments.forEach((element, index) => {
                payments.push({
                    date: formatDate(element.date),
                    amount: element.amount,
                    method: element.method,
                    notes: element.notes
                })
            });

            let history = [];
            let __history = historyParam?.length > 0 ? historyParam : formInputs.history;
            __history.forEach((element, index) => {
                history.push({
                    date: formatDate(element.date),
                    seller_status: element.seller_status,
                    buyer_status: element.buyer_status,
                    process_status: element.process_status,
                    type: element.type,
                    note: element.note
                })
            });

            console.log(`formInputs.deliveryDate :: ${formInputs.deliveryDate}`);
            let terms = {
                currency: formInputs.currency ? formInputs.currency : '',
                paymentMethod: formInputs.paymentMethod ? formInputs.paymentMethod : '',
                deliveryDate: formInputs.deliveryDate && formInputs.deliveryDate != 'Invalid Date' ? formatDate(formInputs.deliveryDate) :  '1900-01-01',
                guarantee: formInputs.guarantee ? formInputs.guarantee : '',
                incoterm: formInputs.incoterm ? formInputs.incoterm : ''
            }

            let shipping = {
                method: formInputs.shippingMethod,
                cost: formInputs.paymentCost == "" ? 0 : formInputs.paymentCost,
                status: formInputs.shippingStatus,
                originAddress: {
                    street: formInputs.originStreet,
                    city: formInputs.originCity,
                    state: formInputs.originState,
                    country: formInputs.originCountry,
                    zipCode: formInputs.originZip,
                    phone: formInputs.originPhone,
                    contact: formInputs.originContact
                },
                destinationAddress: {
                    street: formInputs.destinationStreet,
                    city: formInputs.destinationCity,
                    state: formInputs.destinationState,
                    country: formInputs.destinationCountry,
                    zipCode: formInputs.destinationZip,
                    phone: formInputs.destinationPhone,
                    contact: formInputs.destinationContact
                },
                specialInstructions: formInputs.shippingSpecialInfo,
            }
    
            const input = {
                id: id,
                orderNumber : formInputs.orderNumber,
                company_name : formInputs.companyName,
                company_contact_name: formInputs.companyContactName,
                company_email: formInputs.companyEmail,
                company_address: formInputs.companyAddress,
                buyer_email: (formInputs.buyerEmail+"").trim().toLowerCase(),
                buyer_name: formInputs.buyerName,
                buyer_phone: formInputs.buyerPhone,
                billingAddress: billingAddress,
                products: products,
                terms: terms,
                shipping : shipping,
                additionalFees: fees,
                payments: payments,
                tracking_number : formInputs.trackingNumber,
                seller_status : sellerStatus !== -1 ? sellerStatus : formInputs.sellerStatus,
                buyer_status : buyerStatus === 0 ? buyerStatus : formInputs.buyerStatus,
                total: formInputs.total,
                history: history
            }
            const result = await API.graphql(
                graphqlOperation(updateOrder, { input })
              );
            console.log("Order updated", result);
            setLoading(false);
        }catch(e){
            console.error(e);
            setLoading(false);
        }
        
    }

    const issueOrder = async () =>{
        try{
            setLoading(true);
            let newHistoryItem = {
                process_status: 0,
                date: new Date(),
                type: 0,
            }
            let _prevHist = formInputs.history ? formInputs.history: [];
            setFormInputs({...formInputs, sellerStatus: 1, history: [..._prevHist, newHistoryItem] });
            updateOrderFromForm(1, -1, [..._prevHist, newHistoryItem]);

            //send buyer notification...
            const u = await Auth.currentAuthenticatedUser();
            console.log({u});
            console.log('i18n.languagei18n.language :: ' + i18n.language);

            await sendEmailTemplate({
                templateName: "newOderBuyer", 
                subject: i18n.language == "en" ? "A New Order has been issued for you" : "Se emitió una Nueva Orden para ti en Bainub",
                emailFrom: Constans.FROM_EMAIL,
                recipients: Constans.USE_PRD_EMAIL ? [formInputs.buyerEmail]  : ["victor_calva@mincovac.com", "eskasu@gmail.com"], 
                templateVars: {
                    //username: props.companyPage?.name
                },
                templateLanguage: i18n.language  == "en" ? "en" : "es"
            });

            await sendEmailTemplate({
                templateName: "newOderSeller", 
                subject: i18n.language == "en" ? "You issued a New Order in Bainub" : "Has emitido una Nueva Orden en Bainub",
                emailFrom: Constans.FROM_EMAIL,
                recipients: Constans.USE_PRD_EMAIL ? [u.attributes.email]  : ["victor_calva@mincovac.com", "eskasu@gmail.com"], 
                templateVars: {
                    //username: props.companyPage?.name
                },
                templateLanguage: i18n.language  == "en" ? "en" : "es"
            });

        }catch(e){
            console.error(e);
            setLoading(false);
        }
    }

    const validateIssueOrder = async () =>{
        try{

            if(formInputs.products.length == 0){
                MessageBox.alert(
                    t('orders.form.error-general-detail2'), t('orders.form.error-general'),
                    {
                    type: 'error'
                    }
                );
            }else{
                const user = await Auth.currentAuthenticatedUser();
                console.log({user});
                if(user.attributes.email.toLowerCase() == formInputs.buyerEmail.toLowerCase()){
                    MessageBox.alert(
                        t('orders.form.error-general-detail3'), t('orders.form.error-general'),
                        {
                        type: 'error'
                        }
                    );
                }else{

                    form1.current.validate(async (valid) => {
                        console.log('validator :: ' + valid);
                        if (valid) {
                            setLoading(true);
                            //TODO validar el correo sea un correo de un usuario en Bainub
                            //const userResult = await API.graphql({ query: listUsers, variables: { filter: { email: { eq: (formInputs.buyerEmail + "").trim().toLowerCase() } }}});
                            const inputListUsers = { filter: { email: { eq: (formInputs.buyerEmail + "").trim().toLowerCase() } } }
                            const pagerQuery = new PagerQuery(listUsers, inputListUsers, Object.keys({ listUsers }));
                            const userResult = await pagerQuery.searchPrivateData();
                            console.log({userResult});
                            setLoading(false);
                            if(userResult.data?.listUsers?.items.length > 0){
                                console.log('cambiar status');
                                issueOrder();
                            }else{
                                console.log('Usuario no valido en Bainub')
                                Notification.error({
                                    title: t('orders.form.error-bainub-email'),
                                    message: t('orders.form.error-bainub-email-detail')
                                });
                                buyerEmailRef.current.focus();
                            }
                            
                        } else {
                            console.log('invalid form');
                            Notification.error({
                                title: t('orders.form.error-general'),
                                message: t('orders.form.error-general-detail')
                            });
                        }
                    })
                    setLoading(false);
                }
            }
        }catch(e){
            console.error(e);
            setLoading(false);
        }
    }

    const LogicStatusLabel = () =>{
        return sellerStatus(formInputs.sellerStatus, formInputs.buyerStatus);
    }

    const cancelOrder = async () =>{
        setFormInputs({...formInputs, sellerStatus: 5 });
        setLoading(true);
        try{
            await sendEmailTemplate({
                templateName: "cancelOrderBuyer", 
                subject: i18n.language == "en" ? "Cancelation Request in Bainub" : "Solicitud de Cancelación de Orden en Bainub",
                emailFrom: Constans.FROM_EMAIL,
                recipients: Constans.USE_PRD_EMAIL ? [formInputs.buyerEmail]  : ["victor_calva@mincovac.com", "eskasu@gmail.com"], 
                templateVars: {
                    ordernumber: pad(formInputs.orderNumber, 10)
                },
                templateLanguage: i18n.language  == "en" ? "en" : "es"
            });
            await updateOrderFromForm(5);
            setLoading(false);
        }catch(e){
            console.error(e);
            setLoading(false);
        }
    }

    const updateProductCounter = async() =>{
        try{
            const user = await Auth.currentAuthenticatedUser();
            const result = await API.graphql({ query: listCounterInfoTables, variables: 
                { 
                    filter: 
                        { 
                            owner: { eq: user.username } 
                        } 
                } 
            });
            console.log('NEW PRODUCT :: updateProductCounter');
            console.log({result});
            if(result && result.data && result.data?.listCounterInfoTables?.items.length > 0){
                const input = {
                    id: result.data.listCounterInfoTables.items[0].id,
                    active_orders_total: result.data.listCounterInfoTables.items[0].active_orders_total - 1,
                    completed_orders_total: result.data.listCounterInfoTables.items[0].completed_orders_total + 1
                };
                const resultUpdate = await API.graphql(graphqlOperation(updateCounterInfoTable, { input }));
                console.log({resultUpdate});
            }else{
                console.log('NEW PRODUCT :: updateProductCounter :: doesnt EXIST');
            }
        }catch(e){
            console.error(e);
        }
    }

    const finishOrder = async () =>{
        setFormInputs({...formInputs, sellerStatus: 3 });
        await updateOrderFromForm(3);
        await updateProductCounter();
    }
    
    const backToDraft = async () =>{
        setFormInputs({...formInputs, sellerStatus: 0, buyerStatus: 0 });
        await updateOrderFromForm(0, 0);
    }
    const cloneHandler = async (id) =>{
        console.log('cloneHandler');
        try{
            setLoading(true);
            const orderCloned = await cloneOrder(id);
            //setOrders({...orders, orderCloned});
            //TODO mejorar como se recargar las ordenes...
            //window.location.reload(false);
            console.log('history Push... :: ');
            history.push('/add-order-wizard/' + orderCloned);
            window.location.reload(false);
            setLoading(false);
        }catch(e){
            console.error(e);
            setLoading(false);
        }
        
    }

    const sendNewStatusEmail = () =>{
        sendEmailTemplate({
            templateName: "newOrderStatusGeneral", 
            subject: i18n.language == "en" ? "Your Order Status has been updated" : "Hay una actualización del Estatus de tu Orden",
            emailFrom: Constans.FROM_EMAIL,
            recipients: Constans.USE_PRD_EMAIL ? [formInputs.buyerEmail]  : ["victor_calva@mincovac.com", "eskasu@gmail.com"], 
            templateVars: {
                ordernumber: pad(formInputs.orderNumber, 10)
            },
            templateLanguage: i18n.language  == "en" ? "en" : "es"
        });
    }

    return(
        <div className="order-wizard-div">
            {
                loading && <Loading fullscreen={loading} />
            }

            <WarningModal
                modalWarningVisible={warningCancelModal}
                setModalWarningVisible={showWarningCancelModal}
                action={()=>cancelOrder()}
                title={t('modal.cancelOrder.title')}
                message={t('modal.cancelOrder.message')}
                okButtonText={t('modal.cancelOrder.okButtonText')}
                cancelButtonText={t('modal.cancelOrder.cancelButtonText')}
            />

        <WarningModal
                modalWarningVisible={warningFinishModal}
                setModalWarningVisible={showWarningFinishModal}
                action={()=>finishOrder(formInputs.id)}
                title={t('modal.finishOrder.title')}
                message={t('modal.finishOrder.message')}
                okButtonText={t('modal.finishOrder.okButtonText')}
            />

            <Layout.Row>
                <Layout.Col>
                    &nbsp;
                </Layout.Col>
            </Layout.Row>
            <Card className="order-card"
                header={
                    <>
                    <Layout.Row>
                    <Layout.Col lg="3">
                            <Link to="/orders">All Orders</Link>&gt;
                        </Layout.Col>
                    </Layout.Row>

                    <Layout.Row>
                        <Layout.Col lg="4">
                                <h2 className='order-form-color' style={{borderRight: '1px solid #c1ccd7'}}>{t('orders.form.title')}</h2>
                            </Layout.Col>
                            <Layout.Col lg="2" className='left-space-order pt-1'>
                                <h2>
                                    <LogicStatusLabel />
                                </h2>
                            </Layout.Col>
                            <Layout.Col lg="18">
                                <Layout.Row justify="end">
                                    <Layout.Col lg="24" md="24" sm="24" className='pt-1 align-right' >
                                        <form-button-inverse class="left-space-order">
                                                <Button type="secondary" onClick={()=>history.push('/orders')}>
                                                    {t('orders.form.back-btn')}
                                                </Button>
                                        </form-button-inverse>
                                        {
                                        (formInputs.sellerStatus !== 3 && formInputs.buyerStatus === 0) ||
                                        (formInputs.buyerStatus === 1 && formInputs.sellerStatus === 1)
                                        ?

                                        <form-button-inverse class="left-space-order">
                                                        <Button type="primary" onClick={() => updateOrderFromForm(-1)}>
                                                            {t('orders.form.save-btn')}
                                                        </Button>
                                         </form-button-inverse>

                                        :
                                            <></>
                                    }
                                     {
                                            formInputs.sellerStatus === 1 && formInputs.buyerStatus === 2
                                            ?
                                          
                                                    <form-button style={{display: 'inline-flex'}} class="left-space-order">
                                                        <Button type="primary" onClick={backToDraft}>
                                                            {t('orders.form.back2draft-btn')}
                                                        </Button>
                                                    </form-button>
                                            
                                            :
                                            formInputs.sellerStatus !== 3 && (formInputs.sellerStatus !== 0 && formInputs.sellerStatus !== 5) && !(formInputs.buyerStatus === 2 && formInputs.sellerStatus === 2)
                                            ?
                                            
                                                <form-button style={{display: 'inline-flex'}} class="left-space-order">
                                                    <Button type="primary" onClick={()=>showWarningCancelModal(true)}>
                                                        {t('orders.form.cancel-btn')}
                                                    </Button>
                                                </form-button>
                                         
                                            :
                                                formInputs.sellerStatus === 0
                                                ?
                                                    
                                                        <form-button style={{display: 'inline-flex'}} class="left-space-order">
                                                            <Button type="primary" onClick={validateIssueOrder}>
                                                                    {t('orders.form.issue-btn')}
                                                            </Button>
                                                        </form-button>
                                                   
                                                :
                                                    formInputs.buyerStatus === 2 && (formInputs.sellerStatus === 2 || formInputs.sellerStatus === 1)
                                                    ?
                                                        
                                                            <form-button style={{display: 'inline-flex'}} class="left-space-order">
                                                                <Button type="secondary" onClick={backToDraft}>
                                                                    {t('orders.form.back2draft-btn')}
                                                                </Button>
                                                            </form-button>
                                                        
                                                    :
                                                        <></>
                                        }
                                        <form-button-inverse class="left-space-order">
                                        <Dropdown trigger="click" menu={(
                                            <Dropdown.Menu>
                                                <Dropdown.Item>
                                                    <div onClick={()=>{cloneHandler(formInputs.id)}}>{t('orders.actions.clone-btn')}</div>
                                                    
                                                </Dropdown.Item>
                                                {
                                                        formInputs.buyerStatus === 1 && formInputs.sellerStatus === 1
                                                        ?
                                                        <Dropdown.Item>
                                                            <div onClick={()=>{
                                                                showWarningFinishModal(true);
                                                            }}>{t('orders.actions.finish-btn')}</div>
                                                        </Dropdown.Item>
                                                        :
                                                            <></>
                                                }
                                            </Dropdown.Menu>
                                        )}>
                                                <Button style={{'width': '50px'}} type="secondary">
                                                        More Options
                                                </Button>
                                        </Dropdown>
                                        </form-button-inverse>
                                    </Layout.Col>
    
                                    
                                    
                                    
                                       
                                        
                                </Layout.Row>
                            </Layout.Col>
                    </Layout.Row>
                    <Layout.Row>
                            
                           
                    </Layout.Row>
                    </>
                }
                >
                <Form ref={form1} model={formInputs} className="en-US" rules={rules1} label-position="top">
                    <a name="top"></a>
                    <Summary t={t} formInputs={formInputs} setFormInputs={setFormInputs}/>
                    {
                        formInputs.sellerStatus >= 1
                        ?
                            <HistorySection sendNewStatusEmail={sendNewStatusEmail} setLoading={setLoading} t={t} formInputs={formInputs} setFormInputs={setFormInputs}/>
                        :
                            <></>
                    }
                    
                    <Layout.Row gutter="20">
                        &nbsp;
                        <br/>
                        &nbsp;
                    </Layout.Row>
                    <Layout.Row gutter="20">
                        <div className="form-separator"/>
                    </Layout.Row>
                    <SellerInfo t={t} formInputs={formInputs} setFormInputs={setFormInputs}/>
                    <Layout.Row gutter="20">
                        <div className="form-separator"/>
                    </Layout.Row>
                    <BuyerInfo buyerEmailRef={buyerEmailRef} setLoading={setLoading} t={t} formInputs={formInputs} setFormInputs={setFormInputs}/>
                    <Layout.Row gutter="20">
                        <div className="form-separator"/>
                    </Layout.Row>
                    <ProductInfo setLoading={setLoading} t={t} formInputs={formInputs} setFormInputs={setFormInputs}/>
                    <Layout.Row gutter="20">
                        &nbsp;
                        <br/>
                        &nbsp;
                    </Layout.Row>
                    <Layout.Row gutter="20">
                        <div className="form-separator"/>
                    </Layout.Row>
                    <TermsSection t={t} formInputs={formInputs} setFormInputs={setFormInputs}/>
                    <Layout.Row gutter="20">
                        <div className="form-separator"/>
                    </Layout.Row>
                    <ShippingSection t={t} formInputs={formInputs} setFormInputs={setFormInputs}/>
                    <Layout.Row gutter="20">
                        <div className="form-separator"/>
                    </Layout.Row>
                    <FeeSection setLoading={setLoading} t={t} formInputs={formInputs} setFormInputs={setFormInputs}/>
                    <Layout.Row gutter="20">
                        &nbsp;
                        <br/>
                        &nbsp;
                    </Layout.Row>
                    <Layout.Row gutter="20">
                        <div className="form-separator"/>
                    </Layout.Row>
                    <PaymentSection setLoading={setLoading} t={t} formInputs={formInputs} setFormInputs={setFormInputs}/>
                    <Layout.Row gutter="20">
                        &nbsp;
                        <br/>
                        &nbsp;
                    </Layout.Row>
                    <Layout.Row justify="center" align="middle" type="flex" gutter="20">
                            <Link to="#" onClick={
                                ()=>{
                                    window.scroll({
                                        top: 0, 
                                        left: 0, 
                                        behavior: 'smooth'
                                      });
                                }
                            } ><b>{t('orders.form.back-link')}</b></Link>
                    </Layout.Row>
                </Form>
                
            </Card>

                               
        </div>
    )
}

export default OrderWizard;
