import { Layout, Menu } from 'element-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { useEffect, useState } from 'react';
import { myStoreMenuItems } from '../utils/MenuMyStoreCatalogs';
import userStore from '../store/user';
import menuStore from '../store/menu-selected';

const MyStoreLeftMenu = () => {
    const { t } = useTranslation();
    let history = useHistory();
    const { user } = userStore();  
    const { menuIndex, setMenuIndex } = menuStore();
    const [subMenuName, setSubMenuName] = useState("");
    const [topStyle, setTopStyle] = useState('0');

    function renderMenu() {
        console.log("rendering menu")
        console.log('menuIndex :: ' + menuIndex);
        let menuItems = user.type === "S" ? myStoreMenuItems.admin : myStoreMenuItems.buyer;
        return createMenuItems(menuItems);
    }

    useEffect(() => {
        console.log('useEffect leftMenu :: ' + menuIndex);
        if (menuIndex !== undefined && menuIndex.includes("#")) {
            setTimeout(function() {
                window.location.href = '#' + menuIndex.split("#")[1]
            }, 1000);
            setSubMenuName(menuIndex);
        } else {
            setSubMenuName("");
        }
    }, [menuIndex]);

    useEffect(() => {
        const handleScroll = () => {
            const newTop = window.scrollY === 0 ? '97px' : '0';
            setTopStyle(newTop);
        };

           // Call handleScroll at mount to set initial top style based on current scroll position
           handleScroll();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    function createMenuItems(items) {
        return items.map((item, index) => {
            if (!item.submenu) {
                let clazzName = item.linkTo === menuIndex || item.linkTo === subMenuName ? "cursor menu-selected" : "cursor"
                return (
                    <div onClick={() => {
                        setMenuIndex(item.linkTo);
                        history.push(item.linkTo);
                    }}>
                        <Menu.Item className="menu1-title" index={item.linkTo} key={index}>
                            <label className={clazzName}>
                                {t(item.label)}
                            </label>
                        </Menu.Item>
                    </div>
                );
            } else {
                return (
                    <Menu.SubMenu index="2" title={<label className="menu1-title menu2-title">{t(item.label)}</label>} key={index}>
                        {createMenuItems(item.submenu)}
                    </Menu.SubMenu>
                );
            }
        });
    }

    return (
        <Layout.Row className="tac main-left-menu seller-menu" style={{ top: topStyle, position: 'fixed'}}>
            <Layout.Col span={24}>
                <div className="sidenav">
                    <Menu defaultActive="/my-account" className="el-menu-vertical-demo">
                        <Menu.Item uniqueOpened={true} index="" className="left-menu-title">
                            {'Seller Hub'}
                        </Menu.Item>
                        {renderMenu()}
                    </Menu>
                </div>
            </Layout.Col>
        </Layout.Row>
    );
}

export default MyStoreLeftMenu;
