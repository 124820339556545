import {useEffect, useState, useRef, useCallback} from 'react';
import { MessageBox, Layout, Button, Card, Steps, Form, Input, Loading, Notification, Select, Tag, Radio, Cascader, Checkbox, Dialog, Slider, Tooltip } from 'element-react';
import { useTranslation } from 'react-i18next';
import { Auth, API, graphqlOperation, Storage} from 'aws-amplify';
import {listSubcategorys, listCategorys, getProduct, listCompanys, listCounterInfoTables, listUsers} from '../graphql/queries';
import {createProduct, updateProduct, updateCounterInfoTable} from '../graphql/mutations';
import {engPropositions, spaPropositions} from '../utils/Prepositions';
import {addProductRules0, addProductRules1, addProductRules2} from '../utils/AddProductRules';
import { useHistory, useParams } from "react-router-dom";
import { PhotoPicker } from "aws-amplify-react";
import Cropper from 'react-easy-crop';
import getCroppedImg from '../utils/CroppedImg';
import ThumbPreview from '../components/ThumbPreview';
import {unitOptions, warrantyOptions, customerServiceOptions} from '../utils/Catalogs';
import JoditEditor from "jodit-react";
import aws_exports from "../aws-exports";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api-graphql";
import * as productController from '../graphql/productLambdaInvokes';
import * as categoryController from '../graphql/categoryLambdaInvokes';
import logInDB from '../graphql/logLambdaInvokes';
import * as LogType from '../utils/LogType';
import userStore from '../store/user';
import PagerQuery from '../utils/PagerQuery';
import {FILES_URL} from '../utils/Constants';
import { GET_SIGNED_B64_URL } from '../utils/Constants';

function ProductWizard(){
    const MAX_MESSAGE_LENGTH = 60096; // Adjust based on your needs
    let history = useHistory();
    let { id } = useParams();
    const {user} = userStore();
    const [loading, setLoading] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [categoryFields, setCategoryFields] = useState([]);
    const [sqlCategoryFields, setSqlCategoryFields] = useState([]);
    const [subCategoryField, setSubCategoryField] = useState();
    const [showToolTip1, setShowToolTip1] = useState(false);

    const [photos, setPhotos] = useState([]);
    const [photoDialog, setPhotoDialog] = useState(false);
    const [previewImageUrl, setPreviewImageUrl] = useState(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [processStep, setProcessStep] = useState('select-file');
    const [image, setImage] = useState(null);
    const [croppedAreaPixels, setCroppedAreaPixels] =  useState(null);
    const [croppedImageBase64, setCroppedImageBase64] = useState(null);

    const [photosTechnical, setPhotosTechnical] = useState([]);
    const [photoTechDialog, setPhotoTechDialog] = useState(false);

    const [additionalPhotos, setAdditionalPhotos] = useState([]);
    const [additionalPhotosDialog, setAdditionalPhotosDialog] = useState(false);

    const editor = useRef(null)

    const serializeError = (error) => {
        const errorProperties = {
          name: error.name,
          message: error.message,
          stack: error.stack,
          // Add any custom properties that might exist on your error
          code: error.code,
          // Safely handle circular references
          ...Object.getOwnPropertyNames(error).reduce((acc, key) => {
            try {
              const value = error[key];
              if (typeof value !== 'function' && key !== 'stack') {
                acc[key] = value;
              }
            } catch (e) {
              acc[key] = '[Unable to serialize]';
            }
            return acc;
          }, {})
        };
      
        try {
          return JSON.stringify(errorProperties);
        } catch (e) {
          return JSON.stringify({
            name: 'SerializationError',
            message: 'Failed to serialize error',
            originalError: error.toString()
          });
        }
      }
    /* 
    <table style="width: 100%;"><tbody>
    <tr>
        <td style="text-align: center; background-color: rgb(204, 204, 204);">TITLE 1</td>
        <td style="text-align: center; background-color: rgb(204, 204, 204);">TITLE 2</td>
        <td style="text-align: center; background-color: rgb(204, 204, 204);">TITLE 3</td>
        <td style="text-align: center; background-color: rgb(204, 204, 204);">TITLE 4</td></tr>
    <tr>
        <td rowspan="2">Sub 1</td>
        <td>Example 1</td>
        <td rowspan="2" style="text-align: center;">SubInfo 1</td>
        <td rowspan="2">Other example.</td></tr><tr><td>Example 2</td></tr>
    <tr>
        <td>Sub2</td>
        <td>Example 3</td>
        <td style="text-align: center;">SubInfo 2</td>
        <td>Another Example.</td></tr>
    <tr>
        <td>Sub 3</td>
        <td>Example 4</td>
        <td style="text-align: center;">SubInfo 3</td>
        <td>...</td></tr></tbody></table>
    */
	const [content, setContent] = useState(``);

	const config = {
		readonly: false, // all options from https://xdsoft.net/jodit/doc/
        disablePlugins: ["iframe","link"],
        buttonsMD:  ["bold", "italic", "underline", 'undo',
		'redo', 'fontsize', 'hr', 'table', 'preview', 'left', 'center' ],
        uploader: { url: 'none' },
        buttons: [
            'source',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'superscript',
            'subscript',
            '|',
            'ul',
            'ol',
            '|',
            'font',
            'fontsize',
            'brush',
            'paragraph',
            '|',
            'align',
            'undo',
            'redo',
            '|',
            'table',
            'link',
            '|',
            'selectall',
            'cut',
            'copy',
            'paste',
            'eraser',
            'fullsize',
          ],
	}

    const form0 = useRef(null);
    const form1 = useRef(null);
    const form2 = useRef(null);
    const form3 = useRef(null);
    const [formInputs, setFormInputs] = useState(
        {
            name: '',
            subCategoryId: '',
            cascaderValue: [],
            description: '-',
            minPrice: '',
            maxPrice: '',
            measurement: '',
            minOrder: '',
            packageType: '',
            customizable: false,
            packageCustom: false,
            warranty: false,
            warrantyTime: '',
            tags: [],
            tempTag: '',
            brand: '',
            intExt: '',
            size: '',
            specialFunctions: '',
            color: '',
            material: '',
            titles: '',
            titles2: '',
            voltage: '',
            power: '',
            protection: '',
            lifeTime: '',
            monthlyCapacity: '',
            clientService: '',
            quantityPerPackage: '',
            packageDimensions1: '',
            packageDimensions2: '',
            packageDimensions3: '',
            packageWeight: '',
            updateProcess: false,
            categoryId: '',
            subCategoryName : '',
            key: 'all'
        }
    );
    const { t } = useTranslation();
    const [rules0, setRules0] = useState(null);
    const [rules1, setRules1] = useState(null);
    const [rules2, setRules2] = useState(null);
    const [rules3, setRules3] = useState(null);


    const [options2, setOptions2] = useState([]);

    useEffect(() => {
        const unblock = history.block((location, action) => {
            console.log('action :: ' + action);
            if(action == 'POP'){
                return window.confirm(`
                    Are you sure you want to exit the Product Wizzard?

                    This will erase all your changes.
                `);
            }else{
                return true;
            }
            
        });
      
        return () => {
          unblock();
        };
      }, []);

    useEffect(()=>{
        console.log('Init Product Wizard');
        (async () => {
            try{
                const result = await API.graphql({ query: listCategorys});
                console.log({result});
                const newData = [];
                const _listCatgorys = result.data.listCategorys.items;
                _listCatgorys.sort(compare);
                _listCatgorys.forEach((element, index) => {
                    console.log(element);
                    const parent = {
                        label: element.name,
                        value: element.id,
                        children: []
                    }
                    const _tags = element.tags;
                    _tags.sort(compareTags)
                    _tags.forEach(child =>{
                        const _child =
                            {
                                value: child,
                                label: child
                            }
                        parent.children.push(_child);
                    });

                    newData.push(parent);
                });
                setOptions2(newData);
            }catch(e){
                console.error(e);
                // Use a safe stringification method for the error
                const serializedError = serializeError(e);
                const truncatedMessage = serializedError.substring(0, MAX_MESSAGE_LENGTH);
                logInDB({ message: truncatedMessage, type: LogType.ERROR_TYPE, user: (user!= null && user.username != null) ? user.username : 'anonymous' });
            }
        })();
        setTimeout(function(){
            setRules0(addProductRules0(t));
            setRules1(addProductRules1(t));
            setRules2(addProductRules2(t));
        }, 1500);

        setFormInputs({...formInputs, subCategoryId : ''});
        if(id != '0'){
            console.log('looking for product id :: ' + id);

            (async () => {
                setLoading(true);
                const input = {
                    id: id
                }
                const result  = await API.graphql({
                    query: getProduct,
                    variables: input,
                    authMode: GRAPHQL_AUTH_MODE.AWS_IAM
                  });
                console.log({result});
                //TODO validate if is the same owner, if NOT show error and set ID to 0?...
                let prdInfo = result.data.getProduct;
                let _dimensions = ['','',''];
                try{
                    _dimensions = prdInfo.package_dimensions.split('x');
                }catch(e){
                    console.error(e);
                    _dimensions = ['','',''];
                }
                setFormInputs(
                    {
                        id: prdInfo.id,
                        name: prdInfo.name,
                        subCategoryId: prdInfo.category?.id,
                        //cascaderValue: [],
                        description: prdInfo.description,
                        minPrice: prdInfo.min_price,
                        maxPrice: prdInfo.max_price,
                        measurement: prdInfo.measurement,
                        minOrder: prdInfo.min_order,
                        packageType: prdInfo.package_type,
                        customizable: prdInfo.customizable,
                        packageCustom: prdInfo.package_custom,
                        warranty: false,
                        warrantyTime: prdInfo.warranty_time,
                        tags: prdInfo.tags,
                        tempTag: '',
                        brand: prdInfo.brand,
                        intExt: prdInfo.int_ext,
                        size: prdInfo.size,
                        specialFunctions: prdInfo.special_functions,
                        color: prdInfo.color,
                        material: prdInfo.material,
                        titles: prdInfo.titles,
                        titles2: prdInfo.titles2,
                        voltage: prdInfo.voltage,
                        power: prdInfo.power,
                        protection: prdInfo.protection,
                        lifeTime: prdInfo.life_time,
                        monthlyCapacity: prdInfo.monthly_capacity,
                        clientService: prdInfo.client_service,
                        quantityPerPackage: prdInfo.quantity_per_package,
                        packageDimensions1: _dimensions[0],
                        packageDimensions2: _dimensions[1],
                        packageDimensions3: _dimensions[2],
                        packageWeight: prdInfo.package_weight,
                        updateProcess: true,
                        categoryId : prdInfo.category?.category_id,
                        subCategoryId: prdInfo.category?.id,
                        subCategoryName : prdInfo.category?.name
                    }
                );
                setContent(prdInfo.big_description);
                await setPhotosToUpdate(prdInfo);
                setCurrentStep(1);
                setLoading(false);
            })();
        }
    }, []);


    const getPhotosInfo = async(element, originalArray, index) =>{
        let _fileName = element.key.split('/');
        console.log(_fileName);
        let fileName = '';
        if(_fileName.length > 0){
            fileName = _fileName[_fileName.length - 1];
        }
        console.log('fileName :: ' + fileName);
        //let photoURL = await Storage.get(element.key,{ download: true });
        //console.log({photoURL});
        //let imgBlob = photoURL.Body;
        //console.log({imgBlob});
        //const session = await Auth.currentSession();
        //const token = session.getIdToken().getJwtToken();
        /*const response = await fetch(GET_SIGNED_B64_URL + `?fileName=${element.key}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        });*/
        //const data = await response.json();
        const photoURL = await fetchImageAsBlob('https://images.bainub.com/public/'+encodeURIComponent(element.key), fileName);

        let _photos = originalArray;
        if(photoURL){
            _photos.push({
                src : window.URL.createObjectURL(photoURL.blob),
                name: fileName,
                type: photoURL.type,
                key : element.key,
                index : index
            });
        }
        
        return _photos;
    }

    const fetchImageAsBlob = async (imageUrl, key) => {
        try {
            //imageUrl = Buffer.from(imageUrl, 'base64').toString('utf-8');
          const updatedUrl = imageUrl//.replace(/%20/g, '+');
          //console.log('fetchImageAsBlob :: ' + updatedUrl);
          //const lastPart = updatedUrl.split('/').pop();
          // Reconstruir la URL completa
          //const encodedUrl = updatedUrl.substring(0, updatedUrl.lastIndexOf('/') + 1) + encodeURIComponent(key);
          //console.log('encodedUrl :: ' + encodedUrl)
          const response = await fetch(imageUrl);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const blob = await response.blob();
          const contentType = response.headers.get('content-type');
          console.log('contentType :: ' + contentType)
          return { blob, contentType };
        } catch (error) {
          console.error('Error fetching image:', error);
          //throw error;
          const response = await fetch('https://files.bainub.com/proxy-image?url=' + encodeURIComponent(imageUrl));
          const blob = await response.blob();
          const contentType = response.headers.get('content-type');
          console.log('contentType :: ' + contentType)
          return { blob, contentType };

        }
      };

    const setPhotosToUpdate = async (prdInfo) =>{
        console.log('setPhotosToUpdate');
        let _photos = [];
        await Promise.all(prdInfo.photo.map(async (element, index) => {
            console.log('index :: ' + index);
            await getPhotosInfo(element, _photos, index);
        }));
        console.log('final step')
        console.log({_photos})
        let finalPhotos = [];
        prdInfo.photo.map((element) => {
            console.log({element});
            _photos.find((x) => {
                if(x.key == element.key){
                    finalPhotos.push(x);
                }
            });
        });
        setPhotos(finalPhotos);

        await Promise.all(prdInfo.file.map(async (element, index) => {
            let _photos = await getPhotosInfo(element, photosTechnical, index);
            setPhotosTechnical([..._photos]);
        }));

        await Promise.all(prdInfo.addtional_photo.map(async (element, index) => {
            let _photos = await getPhotosInfo(element, additionalPhotos, index);
            setAdditionalPhotos([..._photos]);
        }));
    }

    const nextStep = () =>{
        console.log('currentStep :: ' + currentStep);
        if(currentStep == 1){
            if (photos.length == 0) {
                MessageBox.alert(t('Please select at least one picture for your product'), 'Warning');
                return;
            }
            console.log('validating form1');
            if(Number(formInputs.minPrice) > Number(formInputs.maxPrice)){
                console.log('minPrice > maxPrice');
                MessageBox.alert(t('product.create.form.price-validation'), 'Warning');
                return;
            }

            console.log({form1});
            console.log({form2});
            form1.current.validate(async(valid) => {
                console.log('validator :: ' + valid);
                if (valid) {
                    setCurrentStep(currentStep + 1);
                } else {
                    console.log('invalid form');
                    window.scroll({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                      });
                }
            });
        }else{
            if(currentStep == 2){
                form2.current.validate(async(valid) => {
                    console.log('validator :: ' + valid);
                    if (valid) {
                        setCurrentStep(currentStep + 1);
                    } else {
                        console.log('invalid form');
                    }
                });
            }else{
                setCurrentStep(currentStep + 1);
            }
        }

    }

    const prevStep = () =>{
        setCurrentStep(currentStep - 1);
    }

    const searchProductCategories = async() =>{
        console.log('searchProductCategories');
        setLoadingBtn(true);
        try{
            console.log('input :: ' + formInputs.name);
            let productName = formInputs.name.split(" ");
            let filter = {
                or: []
            };
            let filterProducts = {
                or: []
            }
            //console.log('productName.length :: ' + productName.length);
            for (var i=0; i < productName.length; i++) {
                console.log('Searching by word :: ' + productName[i]);
                if(!engPropositions.includes(productName[i]) && !spaPropositions.includes(productName[i])){
                    filter.or.push(
                        { tags: { contains: productName[i].toLowerCase() }}
                    );
                    filter.or.push(
                        { tags: { contains: " " + productName[i].toLowerCase() }}
                    );
                }

            }


            filter.or.push(
                { tags: { contains: formInputs.name.toLowerCase() }}
            );
            filter.or.push(
                { tags: { contains: " "+ formInputs.name.toLowerCase() }}
            );

            const result = await API.graphql({ query: listSubcategorys, variables: { filter: filter}});
            console.log({result});
            result.data.listSubcategorys.items.push({
                id : '-1',
                name : t('product.create.form.other-category')
            });
            setCategoryFields(result.data.listSubcategorys.items);

            var sqlResult = await productController.searchProducts({searchTerm : formInputs.name.toLowerCase()});
            console.log({sqlResult});
            let items = [];
            sqlResult.data.forEach(element => {
                if(!items.find(x => x.sub_category_id == element.sub_category_id)){
                    items.push(element);
                }
            });
            var sqlResult2 = await categoryController.searchSubCategory({name : formInputs.name.toLowerCase()});
            console.log({sqlResult2});
            sqlResult2.data.forEach(element => {
                if(!items.find(x => x.sub_category_id == element.dynamo_id)){
                    items.push({
                        sub_category_id : element.dynamo_id,
                        sub_category_name : element.name,
                        category_name : element.category_name
                    });
                }
            });

            console.log({items});

            items = items.sort(dynamicSort("category_name"));

            items.push({
                sub_category_id : '-1',
                sub_category_name : t('product.create.form.other-category')
            });

            setSqlCategoryFields(items);
            //const productsResult = await API.graphql({ query: listProducts, variables: { filter: filterProducts}});
            //console.log({productsResult});
            setLoadingBtn(false);
            logInDB({ message: 'category for product search :: ' + formInputs.name.toLowerCase(), type: LogType.SEARCH_CATEGORY_FOR_PRODUCT, user: user!= null && user.name != null ? user.name : 'anonymous' });
        }catch(e){
            console.error(e);
            const serializedError = serializeError(e);
            const truncatedMessage = serializedError.substring(0, MAX_MESSAGE_LENGTH);
            logInDB({ message: truncatedMessage, type: LogType.ERROR_TYPE, user: (user!= null && user.username != null) ? user.username : 'anonymous' });
        }finally{
            setLoadingBtn(false);
        }
    }

    function dynamicSort(property) {
        var sortOrder = 1;
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a,b) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    const changeCategoryRadio = (subCategoryId, categoryId, subCategoryName, i) =>{
        console.log('subCategoryId :: ' + subCategoryId);
        console.log('categoryId :: ' + categoryId);
        setSubCategoryField(subCategoryId);
        console.log('Index :: ' + i);
        //console.log({categoryFieldsRefs});
        if( subCategoryId != '-1'){
            setFormInputs({...formInputs,
                subCategoryId : subCategoryId,
                categoryId: categoryId,
                subCategoryName : subCategoryName,
                cascaderValue: []});
        }else{
            setFormInputs({...formInputs, subCategoryId : ''});
        }

    }

    const loadSubCategories = async (value) => {
        console.log('loadSubCategories ==> ' + value[0]);
        console.log('loadSubCategories ==> ' + value[1]);

        setLoading(true);
        try{
            /*let filter = {
                category_id: {eq: value[0]}, 
                name: {eq: value[1]}
            };
            const result = await API.graphql({ query: listSubcategorys, variables: { filter: filter}});
            console.log({result});*/
            /* 
            NUEVA en SQL --- LOGICA PARA ENCONRTRAR LA SUB-CATEGORIA ANIDADA CON PAGINACION
            */
            const result = await categoryController.getSubCategoryByCategoryIdAndName({
                categoryId: value[0],
                name: value[1]
            })
            console.log({result});
            setFormInputs({...formInputs, subCategoryId :
                result.data[0].dynamo_id,
                categoryId: result.data[0].dynamo_id.category_id,
                subCategoryName : result.data[0].dynamo_id.name,
                cascaderValue: value
            });
            setLoading(false);
        }catch(e){
            setFormInputs({...formInputs, cascaderValue: value});
            setLoading(false);
            console.error(e);
            const serializedError = serializeError(e);
            const truncatedMessage = serializedError.substring(0, MAX_MESSAGE_LENGTH);
            logInDB({ message: truncatedMessage, type: LogType.ERROR_TYPE, user: (user!= null && user.username != null) ? user.username : 'anonymous' });
        }

    }

    function compare( a, b ) {
        if ( a.name.toLowerCase() < b.name.toLowerCase() ){
          return -1;
        }
        if ( a.name.toLowerCase() > b.name.toLowerCase() ){
          return 1;
        }
        return 0;
      }

      function compareTags( a, b ) {
        if ( a.toLowerCase() < b.toLowerCase() ){
          return -1;
        }
        if ( a.toLowerCase() > b.toLowerCase() ){
          return 1;
        }
        return 0;
      }

      const addKeyword = () =>{
        const _tags = formInputs.tags;
        if(formInputs.tempTag.trim() != ''){
            _tags.push(formInputs.tempTag.trim().toLowerCase());
            setFormInputs({...formInputs, tags: _tags, tempTag: ''})
        }
      }
      const removeTag = (tag) =>{
        console.log('tag to remove :: ' + tag);
        const _tags = formInputs.tags;
        _tags.splice(_tags.indexOf(tag), 1);
        console.log({_tags});
        setFormInputs({...formInputs, tags: _tags})
      }

    const handleSaveMyProduct = () =>{
        form2.current.validate((valid) => {
            console.log('validator step2 :: ' + valid);
            if (!valid) {
                return;
            }else{
                if(formInputs.updateProcess){
                    updateMyProduct();
                }else{
                    saveMyProduct();
                }

            }
        });
    }


    const updateMyProduct = async() =>{
        console.log('about update the product :: ' + id);

        setLoading(true);
        try{
            const user = await Auth.currentAuthenticatedUser();
            //const resultCompanies = await API.graphql(graphqlOperation(listCompanys, { filter: { owner: {eq: user.username } } }))
            const pagerQueryCompanies = new PagerQuery(listCompanys, { filter: { owner: {eq: user.username } } }, Object.keys({ listCompanys }));
            const resultCompanies = await pagerQueryCompanies.searchPrivateData();
            //const resultUser = await API.graphql(graphqlOperation(listUsers, { filter: { cognito_id: {eq: user.username } } }))
            const inputListUsers = { filter: { cognito_id: {eq: user.username } } }
            const pagerQuery = new PagerQuery(listUsers, inputListUsers, Object.keys({ listUsers }));
            const resultUser = await pagerQuery.searchPrivateData();
            console.log({resultCompanies})
            let photosS3Array = await uploadPhotos(user, photos);
            photosS3Array.sort(function(a, b) {
                return a.index - b.index;
            })
            const propertyToRemove = 'index';
            let photosTechnicalS3Array = await uploadPhotos(user, photosTechnical);
            let addtionalPhotosS3Array = await uploadPhotos(user, additionalPhotos);
            
            const FphotosS3Array = photosS3Array.map(obj => {
            const { [propertyToRemove]: _, ...rest } = obj;
                return rest;
            });
            const FphotosTechnicalS3Array = photosTechnicalS3Array.map(obj => {
                const { [propertyToRemove]: _, ...rest } = obj;
                    return rest;
                });
            const FaddtionalPhotosS3Array = addtionalPhotosS3Array.map(obj => {
                const { [propertyToRemove]: _, ...rest } = obj;
                    return rest;
                });
            
            console.log({photosS3Array});
            const input = {
                id: formInputs.id,
                name: formInputs.name,
                description: formInputs.description,
                productCategoryId: formInputs.subCategoryId,
                big_description: content,
                photo: FphotosS3Array,
                file: FphotosTechnicalS3Array,
                addtional_photo: FaddtionalPhotosS3Array,
                min_price: formInputs.minPrice,
                max_price: formInputs.maxPrice,
                measurement: formInputs.measurement,
                min_order: formInputs.minOrder,
                package_type: formInputs.packageType,
                customizable: formInputs.customizable,
                package_custom: formInputs.packageCustom,
                monthly_capacity: formInputs.monthlyCapacity,
                client_service: formInputs.clientService,
                quantity_per_package: formInputs.quantityPerPackage,
                package_dimensions: formInputs.packageDimensions1 + 'x' + formInputs.packageDimensions2 + 'x' + formInputs.packageDimensions3,
                package_weight: formInputs.packageWeight,
                warranty: formInputs.warranty,
                warranty_time: formInputs.warrantyTime,
                tags: formInputs.tags,
                brand: formInputs.brand,
                int_ext: formInputs.intExt,
                size: formInputs.size,
                special_functions: formInputs.specialFunctions,
                color: formInputs.color,
                material: formInputs.material,
                titles: formInputs.titles.toUpperCase(),
                voltage: formInputs.voltage,
                power: formInputs.power,
                protection: formInputs.protection,
                life_time: formInputs.lifeTime,
                titles2: formInputs.titles2.toUpperCase(),
                search_field: formInputs.name.toLowerCase() + ' ' + formInputs.description.toLowerCase() + ' ' + formInputs.brand.toLowerCase(),
                company_name: resultCompanies.data.listCompanys.items.length > 0 ? resultCompanies.data.listCompanys.items[0].name : '',
                company_id: resultCompanies.data.listCompanys.items.length > 0 ? resultCompanies.data.listCompanys.items[0].id : '',
                subcategory_id: formInputs.subCategoryId,
                category_id: formInputs.categoryId,
                subcategory_name: formInputs.subCategoryName,
                company_country: resultUser.data.listUsers.items.length > 0 ? resultUser.data.listUsers.items[0].country : '',
                bainub_account_year: resultCompanies.data.listCompanys.items.length > 0 ? resultCompanies.data.listCompanys.items[0].bainub_account_year : 0,
                verified : resultCompanies.data.listCompanys.items.length > 0 ? resultCompanies.data.listCompanys.items[0].verified : 0,
                supplier_rating : resultCompanies.data.listCompanys.items.length > 0 ? resultCompanies.data.listCompanys.items[0].supplier_rating : null,
                key: "all"
            };

            const result = await API.graphql(
                graphqlOperation(updateProduct, { input })
              );

            productController.updateProduct({
                id: formInputs.id,
                name: formInputs.name,
                description: formInputs.description != null ? formInputs.description.substring(0,400) : '',
                brand: formInputs.brand,
                owner: result.data.updateProduct.owner,
                minPrice: formInputs.minPrice,
                maxPrice: formInputs.maxPrice,
                photoA: photosS3Array[0]?.key,
                photoB : photosS3Array[0]?.bucket,
                photoC : photosS3Array[0]?.region,
                companyId: resultCompanies.data.listCompanys.items.length > 0 ? resultCompanies.data.listCompanys.items[0].id : null,
                status : result.data.updateProduct.status,
                warranty: formInputs.warranty == true ? 1 : 0,
                customizable: formInputs.customizable == true ? 1 : 0,
                minOrder: formInputs.minOrder,
                cert1: formInputs.cert1,
                cert2: formInputs.cert2,
                cert3: formInputs.cert3,
                subCategoryId: formInputs.subCategoryId
            });

            await productController.deleteAllProductTags({productId : formInputs.id});

            console.log('formInputs.tags :: ', formInputs.tags);

            asyncForEach(formInputs.tags, async (tag) => {
                await productController.saveProductTag({
                    productId: formInputs.id,
                    tagName: tag
                });
            });

              console.log("Product updated", result);
              setLoading(false);
              setTimeout(function(){
                Notification({
                    title: t('product.create.form.update-success-title'),
                    message: t('product.create.form.update-success-msg'),
                    type: 'success'
                });
                //@ts-ignore
                history.push("/product-review/" + result.data.updateProduct.id);
              },300);
        }catch(e){
            console.error(e)
            const serializedError = serializeError(e);
            const truncatedMessage = serializedError.substring(0, MAX_MESSAGE_LENGTH);
            logInDB({ message: truncatedMessage, type: LogType.ERROR_TYPE, user: (user!= null && user.username != null) ? user.username : 'anonymous' });
            setLoading(false);
            if(e === 'The user is not authenticated'){
                history.push("/");
            }
        }
    }
      const cutPhoto = useCallback(async () => {
        try {
          const croppedImage = await getCroppedImg(
            previewImageUrl,
            croppedAreaPixels,
            0
          )
          console.log('donee', { croppedImage })
          setProcessStep('image-cropped-preview');
          setCroppedImageBase64(croppedImage)
        } catch (e) {
          console.error(e)
        }
      }, [croppedAreaPixels])

    const addPhotoToCarrousel = () =>{
        console.log({image});
        console.log('photoDialog :: ' + photoDialog);
        let _photos = photoDialog ? photos : photoTechDialog ? photosTechnical : additionalPhotos;
        _photos.push({
            src : croppedImageBase64,
            name: image.name,
            type: image.type,
            index: _photos.length
        });
        if(photoDialog){
            setPhotos([..._photos]);
        }else{
            if(photoTechDialog){
                setPhotosTechnical([..._photos]);
            }else{
                setAdditionalPhotos([..._photos]);
            }
        }

        setProcessStep('select-file');
        setPhotoDialog(false);
        setPhotoTechDialog(false);
        setAdditionalPhotosDialog(false);
        console.log(photos);
    }

    const handleImageDelete = (index, indexPage) =>{
        console.log('Deleting img ::  ' + index);
        let _photos = indexPage === 1 ? photos : indexPage === 2 ? additionalPhotos : photosTechnical;
        _photos.splice(index, 1);
        if(indexPage === 1){
            setPhotos([..._photos]);
        }else{
            if(indexPage === 2){
                setAdditionalPhotos([..._photos]);
            }else{
                setPhotosTechnical([..._photos]);
            }
        }
    }

    const uploadPhotos = async (user, arrayToUpload) =>{
        const _keys = [];

        await Promise.all(arrayToUpload.map(async (element) => {
            try{

                    const session = await Auth.currentSession();
                    const token = session.getIdToken().getJwtToken();
                    let croppedImageData = await fetch(element.src);
                    let croppedImageBlob = await croppedImageData.blob();

                    let fileName = `/${user.username}/${formInputs.name}/${Date.now()}-${
                        element.name
                    }`;
                    /*const uploadedFile = await Storage.put(fileName, croppedImageBlob, {
                        contentType: element.type,
                        level: 'public',
                        progressCallback: progress => {
                        console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
                        const percentUploaded = Math.round(
                            (progress.loaded / progress.total) * 100
                        );
                        console.log(percentUploaded);//Aqui podemos mostrar esto en pantalla de algun modo (en un LOADER...)
                        }
                    });*/
                    const base64Data = await blobToBase64(croppedImageBlob);
                    const base64Content = base64Data.split(',')[1];

                    /*const formData = new FormData();
                    formData.append('file', croppedImageBlob);
                    formData.append('fileName', fileName);
                    formData.append('fileType', element.type);
                    */
                    //console.log('token :: ' + token);
                    const response = await fetch(FILES_URL, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': token
                        },
                        body: JSON.stringify(
                            {
                            fileName: fileName,
                            fileType: element.type,
                            file: base64Content
                        })
                    });
                    
                    const data = await response.json();
                    console.log(data);

                    const file = {
                        //@ts-ignore
                        key: fileName,
                        bucket: aws_exports.aws_user_files_s3_bucket,
                        region: aws_exports.aws_project_region,
                        index: element.index
                    };
                    _keys.push(file);

            }catch(e){
                console.error(e);
                const serializedError = serializeError(e);
                const truncatedMessage = serializedError.substring(0, MAX_MESSAGE_LENGTH);
                logInDB({ message: truncatedMessage, type: LogType.ERROR_TYPE_PROMISE, user: (user!= null && user.username != null) ? user.username : 'anonymous' });
            }

        }));
        return _keys;
    }

    function blobToBase64(blob) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      }

    const updateProductCounter = async(user) =>{
        try{
            const result = await API.graphql({ query: listCounterInfoTables, variables:
                {
                    filter:
                        {
                            owner: { eq: user.username }
                        }
                }
            });
            console.log('NEW PRODUCT :: updateProductCounter');
            console.log({result});
            if(result && result.data && result.data?.listCounterInfoTables?.items.length > 0){
                const input = {
                    id: result.data.listCounterInfoTables.items[0].id,
                    products_total: result.data.listCounterInfoTables.items[0].products_total + 1
                };
                const resultUpdate = await API.graphql(graphqlOperation(updateCounterInfoTable, { input }));
                console.log({resultUpdate});
            }else{
                console.log('NEW PRODUCT :: updateProductCounter :: doesnt EXIST');
            }
            //TODO save to pg LOG table
        }catch(e){
            console.error(e);
            const serializedError = serializeError(e);
            const truncatedMessage = serializedError.substring(0, MAX_MESSAGE_LENGTH);
            logInDB({ message: truncatedMessage, type: LogType.ERROR_TYPE, user: (user!= null && user.username != null) ? user.username : 'anonymous' });
        }
    }

    const saveMyProduct = async() =>{
        setLoading(true);
        try{
            console.log('saving product');
            const user = await Auth.currentAuthenticatedUser();
            //const resultCompanies = await API.graphql(graphqlOperation(listCompanys, { filter: { owner: {eq: user.username } } }))
            const pagerQueryCompanies = new PagerQuery(listCompanys, { filter: { owner: {eq: user.username } } }, Object.keys({ listCompanys }));
            const resultCompanies = await pagerQueryCompanies.searchPrivateData();

            //const resultUser = await API.graphql(graphqlOperation(listUsers, {filter: {cognito_id: {eq: user.username}}}))
            const inputListUsers = { filter: { cognito_id: {eq: user.username } } }
            const pagerQuery = new PagerQuery(listUsers, inputListUsers, Object.keys({ listUsers }));
            const resultUser = await pagerQuery.searchPrivateData();
            console.log({resultCompanies})

            let photosS3Array = await uploadPhotos(user, photos);
            photosS3Array.sort(function(a, b) {
                return a.index - b.index;
            })
            const propertyToRemove = 'index';
            let photosTechnicalS3Array = await uploadPhotos(user, photosTechnical);
            let addtionalPhotosS3Array = await uploadPhotos(user, additionalPhotos);

            const FphotosS3Array = photosS3Array.map(obj => {
                const { [propertyToRemove]: _, ...rest } = obj;
                    return rest;
                });
            const FphotosTechnicalS3Array = photosTechnicalS3Array.map(obj => {
                const { [propertyToRemove]: _, ...rest } = obj;
                    return rest;
                });
            const FaddtionalPhotosS3Array = addtionalPhotosS3Array.map(obj => {
                const { [propertyToRemove]: _, ...rest } = obj;
                    return rest;
                });

            console.log({photosS3Array});

            const input = {
                name: formInputs.name,
                description: formInputs.description != null ? formInputs.description.substring(0,400) : '',
                productCategoryId: formInputs.subCategoryId,
                big_description: content,
                photo: FphotosS3Array,
                file: FphotosTechnicalS3Array,
                addtional_photo: FaddtionalPhotosS3Array,
                min_price: formInputs.minPrice,
                max_price: formInputs.maxPrice,
                measurement: formInputs.measurement,
                min_order: formInputs.minOrder,
                package_type: formInputs.packageType,
                customizable: formInputs.customizable,
                package_custom: formInputs.packageCustom,
                monthly_capacity: formInputs.monthlyCapacity,
                client_service: formInputs.clientService,
                quantity_per_package: formInputs.quantityPerPackage,
                package_dimensions: formInputs.packageDimensions1 + 'x' + formInputs.packageDimensions2 + 'x' + formInputs.packageDimensions3,
                package_weight: formInputs.packageWeight,
                warranty: formInputs.warranty,
                warranty_time: formInputs.warrantyTime,
                tags: formInputs.tags,
                brand: formInputs.brand,
                int_ext: formInputs.intExt,
                size: formInputs.size,
                special_functions: formInputs.specialFunctions,
                color: formInputs.color,
                material: formInputs.material,
                titles: formInputs.titles.toUpperCase(),
                voltage: formInputs.voltage,
                power: formInputs.power,
                protection: formInputs.protection,
                life_time: formInputs.lifeTime,
                titles2: formInputs.titles2.toUpperCase(),
                views: 0,
                search_field: formInputs.name.toLowerCase() + ' ' + formInputs.description.toLowerCase() + ' ' + formInputs.brand.toLowerCase(),
                status: 1,
                company_name: resultCompanies.data.listCompanys.items.length > 0 ? resultCompanies.data.listCompanys.items[0].name : '',
                company_id: resultCompanies.data.listCompanys.items.length > 0 ? resultCompanies.data.listCompanys.items[0].id : '',
                subcategory_id: formInputs.subCategoryId,
                category_id: formInputs.categoryId,
                subcategory_name : formInputs.subCategoryName,
                company_country: resultUser.data.listUsers.items.length > 0 ? resultUser.data.listUsers.items[0].country : '',
                bainub_account_year: resultCompanies.data.listCompanys.items.length > 0 ? resultCompanies.data.listCompanys.items[0].bainub_account_year : 0,
                verified : resultCompanies.data.listCompanys.items.length > 0 ? resultCompanies.data.listCompanys.items[0].verified : 0,
                supplier_rating : resultCompanies.data.listCompanys.items.length > 0 ? resultCompanies.data.listCompanys.items[0].supplier_rating : null,
                key : "all"
            };

            const result = await API.graphql(
                graphqlOperation(createProduct, { input })
              );

            await productController.saveProduct({
                id: result.data.createProduct.id,
                name: formInputs.name,
                description: formInputs.description,
                brand: formInputs.brand,
                owner: result.data.createProduct.owner,
                minPrice: formInputs.minPrice,
                maxPrice: formInputs.maxPrice,
                photoA: photosS3Array[0]?.key,
                photoB : photosS3Array[0]?.bucket,
                photoC : photosS3Array[0]?.region,
                companyId: resultCompanies.data.listCompanys.items.length > 0 ? resultCompanies.data.listCompanys.items[0].id : null,
                status : 1,
                warranty: formInputs.warranty == true ? 1 : 0,
                customizable: formInputs.customizable == true ? 1 : 0,
                minOrder: formInputs.minOrder,
                cert1: formInputs.cert1,
                cert2: formInputs.cert2,
                cert3: formInputs.cert3,
                productOrder: 100,
                subCategoryId: formInputs.subCategoryId,
                featured: 0,
                currentUser: user.username,
                company_country: resultUser.data.listUsers.items.length > 0 ? resultUser.data.listUsers.items[0].country : '',
                bainub_account_year: resultCompanies.data.listCompanys.items.length > 0 ? resultCompanies.data.listCompanys.items[0].bainub_account_year : 0,
                //TODO guardar verified and supplier_rating
            });

            asyncForEach(formInputs.tags, async (tag) => {
                await productController.saveProductTag({
                    productId: result.data.createProduct.id,
                    tagName: tag
                });
            });

              console.log("Product created", result);
              await updateProductCounter(user);
              setLoading(false);

              setTimeout(function(){
                Notification({
                    title: t('product.create.form.success-title'),
                    message: t('product.create.form.success-msg'),
                    type: 'success'
                });
                //@ts-ignore
                history.push("/product-review/" + result.data.createProduct.id);
              },300);
        }catch(e){
            console.error(e)
            const serializedError = serializeError(e);
            const truncatedMessage = serializedError.substring(0, MAX_MESSAGE_LENGTH);
            logInDB({ message: truncatedMessage, type: LogType.ERROR_TYPE, user: (user!= null && user.username != null) ? user.username : 'anonymous' });
            setLoading(false);
            if(e === 'The user is not authenticated'){
                history.push("/");
            }
        }
    }

    async function asyncForEach(array, callback) {
        for (let index = 0; index < array.length; index++) {
          await callback(array[index], index, array);
        }
    }

    const compressImage = (file) => {
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          
          reader.onload = (event) => {
            const img = new Image();
            img.src = event.target.result;
            
            img.onload = () => {
              const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');
              
              // Calculate new dimensions while maintaining aspect ratio
              let width = img.width;
              let height = img.height;
              const maxWidth = 1024;
              const maxHeight = 1024;
              
              if (width > height) {
                if (width > maxWidth) {
                  height = Math.round((height * maxWidth) / width);
                  width = maxWidth;
                }
              } else {
                if (height > maxHeight) {
                  width = Math.round((width * maxHeight) / height);
                  height = maxHeight;
                }
              }
              
              canvas.width = width;
              canvas.height = height;
              
              ctx.drawImage(img, 0, 0, width, height);
              
              // Get compressed preview URL
              const compressedPreview = canvas.toDataURL('image/jpeg', 0.9);
              setPreviewImageUrl(compressedPreview);
              console.log('compressedPreview :: ' + compressedPreview);
              // Convert to Blob with quality control
              canvas.toBlob((blob) => {
                resolve(blob);
              }, 'image/jpeg', 0.9); // Adjust quality here (0.7 = 70% quality)
            };
          };
        });
      };


    const onPhotoPickerPick = async (file) => {
        console.log('onPhotoPicketPick');
        console.log({file});
        /*let reader = new FileReader();
        reader.onloadend = () => {
            console.log({reader});
            setPreviewImageUrl(reader.result);
        }
        reader.readAsDataURL(file.file);*/
        // Compress the image
        const compressedBlob = await compressImage(file.file);
                
        // Create a File from the Blob
        const compressedFile = new File([compressedBlob], file.name, {
        type: 'image/jpeg',
        lastModified: Date.now(),
        });

        setImage(compressedFile);
        console.log(file);
        setProcessStep('crop-image')
    }

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        console.log({croppedArea});
        console.log({croppedAreaPixels});
        setCroppedAreaPixels(croppedAreaPixels);
      }, [])
    const submitForm = (e) =>{
        e.preventDefault();
        searchProductCategories();
    }
    return(
        <div className="product-wizard-div">
            {
                loading && <Loading fullscreen={loading} />
            }
            <div>

            <Dialog size="full"
                visible={photoDialog || photoTechDialog || additionalPhotosDialog}
                onCancel={ () => {
                    setPhotoDialog(false);
                    setPhotoTechDialog(false);
                    setAdditionalPhotosDialog(false)
                    setProcessStep('select-file');
                } }
            >
                <Dialog.Body>
                        {
                        processStep === 'select-file'
                        ?
                            <PhotoPicker
                                title="Click to select a file ..."
                                onPick={onPhotoPickerPick}
                                theme={{
                                    sectionHeader: { display: "none" },
                                    photoPlaceholder:{ display: "none" },
                                    photoPickerButton: {
                                        width: '100%',
                                        borderRadius: '27px',
                                        backgroundColor: '#FF6D73',
                                        borderColor: 'transparent',
                                        fontWeight: 'bold',
                                        boxShadow: '3px 5px 13px rgb(0 0 0 / 10%);',
                                        color: 'white',
                                        padding: '10px 15px'
                                    }
                                }}
                            />
                        :
                            <></>
                        }
                        {
                            previewImageUrl && processStep === 'crop-image'
                            ?
                                <>
                                <div className="crop-container">
                                    <Cropper
                                    minZoom={0.4}
                                    image={previewImageUrl}
                                    crop={crop}
                                    zoom={zoom}
                                    aspect={4 / 3}
                                    restrictPosition={false}
                                    onCropChange={setCrop}
                                    onCropComplete={onCropComplete}
                                    onZoomChange={setZoom}
                                    cropSize={{width: 400, height: 400}}
                                    />
                                </div>
                                <div className="controls">
                                    <Slider
                                    value={zoom}
                                    min={0.4}
                                    max={3}
                                    step={0.1}
                                    aria-labelledby="Zoom"
                                    onChange={(zoom) => setZoom(zoom)}
                                    classes={{ root: 'slider' }}
                                    />
                                </div>
                                </>
                            :
                                <></>
                        }
                        { croppedImageBase64 && processStep === 'image-cropped-preview' ?
                        <Layout.Col xs="24" sm="24" md="24" lg="24" >
                            <div className="center text-center">
                                <img
                                    style={{height: "70vh"}}
                                    src={croppedImageBase64}
                                    alt=""
                                />
                            </div>
                        </Layout.Col> : <></>
                    }

                </Dialog.Body>
                <Dialog.Footer className="dialog-footer">
                    <form-button-inverse style={{'padding-right' : '10px'}}>
                        <Button onClick={ () => {
                            setPhotoDialog(false);
                            setPhotoTechDialog(false);
                            setAdditionalPhotosDialog(false);
                            setProcessStep('select-file');
                        }
                        }>{t('product.create.form.cancel')}</Button>
                    </form-button-inverse>
                    <form-button>

                    {
                        processStep === 'select-file' ? <></>
                        : ( processStep === 'crop-image' ?
                            <Button type="primary" onClick={ () => cutPhoto() }>{t('product.create.form.cut')}</Button> :
                            <Button type="primary" onClick={ () => addPhotoToCarrousel() }>{t('product.create.form.add')}</Button>)
                    }
                    </form-button>
                </Dialog.Footer>
            </Dialog>

                <Layout.Row gutter="20">
                            <Layout.Col offset="1" span="20">
                                <h2>{t('product.create.title')}</h2>
                            </Layout.Col>
                </Layout.Row>
                <Layout.Row gutter="20">
                            <Layout.Col offset="1" span="20">
                                &nbsp;
                            </Layout.Col>
                </Layout.Row>
                <Layout.Row gutter="20">
                            <Layout.Col offset="1" span="22">
                                    <Card className="box-card">
                                        <Layout.Row type="flex" justify="center">
                                            <Layout.Col offset="1" lg="10" tag="creat-mystore-steps">
                                                <Steps space={120} active={currentStep} finishStatus="success">
                                                    <Steps.Step></Steps.Step>
                                                    <Steps.Step></Steps.Step>
                                                    <Steps.Step></Steps.Step>
                                                    <Steps.Step></Steps.Step>
                                                </Steps>
                                            </Layout.Col>
                                        </Layout.Row>
                                        <Layout.Row gutter="20">
                                                <Layout.Col offset="1" span="20">
                                                    &nbsp;
                                                </Layout.Col>
                                                <Layout.Col offset="1" span="20">
                                                    &nbsp;
                                                </Layout.Col>
                                        </Layout.Row>
                                        <Form ref={form0} model={formInputs} className="en-US" rules={rules0}
                                        label-position="top" onSubmit={submitForm}
                                        >
                                            {
                                                currentStep === 0
                                                ?
                                                    <div className="create-company-step0">
                                                        <Layout.Row type="flex">
                                                                <Layout.Col span="20" offset="1" xs="20" sm="20" lg="16">
                                                                    <Form.Item label={t('product.create.form.step1-title')} prop="name">
                                                                        <div className="inline-flex-400px space">
                                                                            <Input
                                                                                maxlength={120}
                                                                                value={formInputs.name}
                                                                                onChange={(v) => {setFormInputs({...formInputs, name: v })}}
                                                                                />
                                                                            <form-button-inverse class="left-space">
                                                                                <Button loading={loadingBtn} onClick={searchProductCategories} type="primary">
                                                                                        {t('product.create.form.button-search')}
                                                                                </Button>
                                                                            </form-button-inverse>
                                                                        </div>
                                                                    </Form.Item>
                                                                </Layout.Col>

                                                        </Layout.Row>
                                                        <Layout.Row type="flex">
                                                            <Layout.Col span="20" offset="1" xs="20" sm="20" lg="18">
                                                                <label className="el-form-item__label italic">{t('product.create.form.step1-help')}</label>
                                                            </Layout.Col>
                                                        </Layout.Row>
                                                        <Layout.Row type="flex">
                                                            <Layout.Col span="20" offset="1" xs="20" sm="20" lg="18">
                                                                &nbsp;
                                                            </Layout.Col>
                                                        </Layout.Row>
                                                        {
                                                            /*
                                                            categoryFields.map((element, i) =>{
                                                                return(
                                                                    <Layout.Row type="flex">
                                                                        <Layout.Col span="20" offset="1" xs="20" sm="20" lg="18">
                                                                            <Radio.Group value={subCategoryField} onChange={()=>changeCategoryRadio(element.id, element.category_id, element.name, i)}>
                                                                                <Radio value={element.id} >
                                                                                        <div className="radio-text">{element.name}</div>
                                                                                        {
                                                                                            element.id != '-1'
                                                                                            ?
                                                                                                <p className="product-category-sub-title">{element.category_name} {'>'} {element.name}</p>
                                                                                            : ''
                                                                                        }

                                                                                </Radio>
                                                                            </Radio.Group>
                                                                        </Layout.Col>
                                                                    </Layout.Row>
                                                                )
                                                            })
                                                            */
                                                            sqlCategoryFields.map((element, i) =>{
                                                                return(
                                                                    <Layout.Row type="flex">
                                                                        <Layout.Col span="20" offset="1" xs="20" sm="20" lg="18">
                                                                            <Radio.Group value={subCategoryField} onChange={()=>changeCategoryRadio(element.sub_category_id, element.category_id, element.sub_category_name, i)}>
                                                                                <Radio value={element.sub_category_id} >
                                                                                        <div className="radio-text">{element.sub_category_name}</div>
                                                                                        {
                                                                                            element.sub_category_id != '-1'
                                                                                            ?
                                                                                                <p className="product-category-sub-title">{element.category_name} {'>'} {element.sub_category_name}</p>
                                                                                            : ''
                                                                                        }

                                                                                </Radio>
                                                                            </Radio.Group>
                                                                        </Layout.Col>
                                                                    </Layout.Row>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            categoryFields.length >= 1 && subCategoryField === '-1'
                                                            ?
                                                                <Layout.Row type="flex">
                                                                    <Layout.Col span="20" offset="1" xs="20" sm="20" lg="18">
                                                                        <Cascader className="cascader-add-product"
                                                                            options={options2}
                                                                            value={formInputs.cascaderValue}
                                                                            onChange={ (v) => {loadSubCategories(v)}}
                                                                        />
                                                                    </Layout.Col>
                                                                </Layout.Row>
                                                            :
                                                                <></>

                                                        }
                                                        <Layout.Row type="flex" justify="end" >
                                                                <Layout.Col tag="form-button" pull="1" xs="20" sm="20" lg="20">
                                                                    <Form.Item>
                                                                        <Button type="primary" disabled={formInputs.subCategoryId == '' ? true: false} onClick={nextStep}>
                                                                                {t('my-store.create.form.button-next')}
                                                                        </Button>
                                                                    </Form.Item>
                                                                </Layout.Col>
                                                        </Layout.Row>

                                                    </div>
                                                :
                                                    <></>

                                            }
                                        </Form>

                                        <Form ref={form1} model={formInputs} className="en-US" rules={rules1} label-position="top">
                                            {
                                                currentStep === 1 ?
                                                    <div>
                                                        <Layout.Row type="flex">
                                                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="14">
                                                                    <Form.Item label={t('product.create.form.name')} prop="name">
                                                                        <Input
                                                                            value={formInputs.name}
                                                                            onChange={(v) => {setFormInputs({...formInputs, name: v })}}
                                                                            />
                                                                    </Form.Item>
                                                                </Layout.Col>
                                                        </Layout.Row>
                                                        {
                                                            /*
                                                            <Layout.Row type="flex">
                                                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="14">
                                                                    <Form.Item label={t('product.create.form.description')} prop="description">
                                                                        <Input
                                                                        type="textarea"
                                                                            value={formInputs.description}
                                                                            autosize={{ minRows: 4, maxRows: 5}}
                                                                            onChange={(v) => {setFormInputs({...formInputs, description: v })}}
                                                                            />
                                                                    </Form.Item>
                                                                </Layout.Col>
                                                            </Layout.Row>
                                                            */
                                                        }

                                                        <Layout.Row type="flex">
                                                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="3">
                                                                    <Form.Item label={t('Minimum Price')} prop="minPrice">
                                                                            <Input
                                                                                value={formInputs.minPrice}
                                                                                placeholder={t('product.create.form.min-price')}
                                                                                onChange={(v) => {setFormInputs({...formInputs, minPrice: v })}}
                                                                            />
                                                                    </Form.Item>
                                                                </Layout.Col>
                                                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="1">
                                                                    <div className="hyphen-center">
                                                                        -
                                                                    </div>
                                                                </Layout.Col>
                                                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="4">
                                                                    <Form.Item label="Maximum Price" prop="maxPrice">           
                                                                        <div style={{display: 'inline-flex'}}>
                                                                            <Input
                                                                                value={formInputs.maxPrice}
                                                                                placeholder={t('product.create.form.max-price')}
                                                                                onChange={(v) => {setFormInputs({...formInputs, maxPrice: v })}}
                                                                            />
                                                                            <label className="label-custom p-l-sm">USD</label>
                                                                        </div>
                                                                    </Form.Item>
                                                                </Layout.Col>
                                                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="3">
                                                                    <Form.Item label={t('product.create.form.measurement') } prop="measurement">

                                                                        <Select value={formInputs.measurement}
                                                                            onChange={(v) => {setFormInputs({...formInputs, measurement: v })}}
                                                                        >
                                                                        {
                                                                            unitOptions.options.map(el => {
                                                                            return <Select.Option key={el.value} label={el.label} value={el.value} />
                                                                            })
                                                                        }
                                                                        </Select>


                                                                    </Form.Item>
                                                                </Layout.Col>

                                                        </Layout.Row>
                                                        <Layout.Row type="flex">
                                                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="3">
                                                                    <Form.Item prop="customizable">
                                                                    <Checkbox
                                                                        checked={formInputs.customizable ? true : false}
                                                                        onChange={(v) => {setFormInputs({...formInputs, customizable: v })}}>
                                                                            {t('product.create.form.customizable')}
                                                                        </Checkbox>
                                                                    </Form.Item>
                                                                </Layout.Col>
                                                        </Layout.Row>
                                                        <Layout.Row type="flex">
                                                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="3">
                                                                    <Form.Item prop="packageCustom">
                                                                    <Checkbox
                                                                        checked={formInputs.packageCustom ? true : false}
                                                                        onChange={(v) => {setFormInputs({...formInputs, packageCustom: v })}}>
                                                                            {t('product.create.form.package-custom')}
                                                                        </Checkbox>
                                                                    </Form.Item>
                                                                </Layout.Col>
                                                        </Layout.Row>
                                                        {
                                                        /*
                                                        <Layout.Row type="flex">
                                                            <Layout.Col span="24" offset="1" xs="20" sm="20" lg="3">
                                                                <Form.Item prop="warranty">
                                                                <Checkbox
                                                                    checked={formInputs.warranty}
                                                                    onChange={(v) => {setFormInputs({...formInputs, warranty: v })}}>
                                                                        {t('product.create.form.warranty')}
                                                                    </Checkbox>
                                                                </Form.Item>
                                                            </Layout.Col>
                                                        </Layout.Row>
                                                        */
                                                        }


                                                        <Layout.Row type="flex">
                                                                <Layout.Col tag="sub-product-items" span="24" xs="20" sm="20" lg="12">
                                                                    <Layout.Row type="flex">
                                                                        <Layout.Col span="24" offset="1" xs="20" sm="20" lg="24">
                                                                            <h3>{t('product.create.form.sub-title1')}</h3>
                                                                        </Layout.Col>
                                                                    </Layout.Row>
                                                                    <Layout.Row type="flex">
                                                                        <Layout.Col span="24" offset="1" xs="20" sm="20" lg="24">
                                                                            <Form.Item style={{display: 'inline-flex'}} label={t('product.create.form.min-order') } prop="minOrder">
                                                                                <div style={{display: 'inline-flex'}}>
                                                                                <Input
                                                                                    className='input-customA'
                                                                                    value={formInputs.minOrder}
                                                                                    onChange={(v) => {setFormInputs({...formInputs, minOrder: v })}}
                                                                                    />
                                                                                    <label className="label-custom p-l-sm">{formInputs.measurement}s</label>
                                                                                </div>
                                                                            </Form.Item>
                                                                        </Layout.Col>
                                                                    </Layout.Row>

                                                                    <Layout.Row type="flex">
                                                                        <Layout.Col span="24" offset="1" xs="20" sm="20" lg="24">
                                                                            <Form.Item style={{display: 'inline-flex'}} label={t('product.create.form.warranty-time') } prop="warrantyTime">
                                                                                <Select value={formInputs.warrantyTime}
                                                                                    onChange={(v) => {setFormInputs({...formInputs, warrantyTime: v })}}
                                                                                >
                                                                                    {
                                                                                        warrantyOptions.options.map(el => {
                                                                                        return <Select.Option key={el.value} label={el.label} value={el.value} />
                                                                                        })
                                                                                    }
                                                                                </Select>
                                                                            </Form.Item>
                                                                        </Layout.Col>
                                                                    </Layout.Row>

                                                                    <Layout.Row type="flex">
                                                                        <Layout.Col span="24" offset="1" xs="20" sm="20" lg="24">
                                                                            <Form.Item style={{display: 'inline-flex'}} label={t('product.create.form.monthly-capacity') } prop="monthlyCapacity">
                                                                                <div style={{display: 'inline-flex'}}>
                                                                                <Input
                                                                                    className='input-customA'
                                                                                    value={formInputs.monthlyCapacity}
                                                                                    onChange={(v) => {setFormInputs({...formInputs, monthlyCapacity: v })}}
                                                                                    />
                                                                                <label className="label-custom p-l-sm">{formInputs.measurement}s/month</label>
                                                                                </div>
                                                                            </Form.Item>
                                                                        </Layout.Col>
                                                                    </Layout.Row>

                                                                    <Layout.Row type="flex">
                                                                        <Layout.Col span="24" offset="1" xs="20" sm="20" lg="24">
                                                                            <Form.Item style={{display: 'inline-flex'}} label={t('product.create.form.client-service') } prop="clientService">
                                                                                    <Select value={formInputs.clientService}
                                                                                        onChange={(v) => {setFormInputs({...formInputs, clientService: v })}}
                                                                                    >
                                                                                        {
                                                                                            customerServiceOptions.options.map(el => {
                                                                                            return <Select.Option key={el.value} label={el.label} value={el.value} />
                                                                                            })
                                                                                        }
                                                                                    </Select>
                                                                            </Form.Item>
                                                                        </Layout.Col>
                                                                    </Layout.Row>


                                                                </Layout.Col>
                                                                <Layout.Col tag="sub-product-items" span="24" xs="20" sm="20" lg="12">
                                                                    <Layout.Row type="flex">
                                                                        <Layout.Col span="24" offset="1" xs="20" sm="20" lg="24">
                                                                            <h3>{t('product.create.form.sub-title2')}</h3>
                                                                        </Layout.Col>
                                                                    </Layout.Row>

                                                                    <Layout.Row type="flex">
                                                                        <Layout.Col span="24" offset="1" xs="20" sm="20" lg="24">
                                                                            <Form.Item style={{display: 'inline-flex'}} label={t('product.create.form.package-type') } prop="packageType">
                                                                            <Tooltip className="item" effect="dark" visible={showToolTip1} content={t('product.create.form.tip-package-type')} placement="top">
                                                                                <Input  onFocus={()=>{console.log('focus fields'); setShowToolTip1(true)}}
                                                                                        onBlur={()=>{console.log('focus fields'); setShowToolTip1(false)}}
                                                                                        value={formInputs.packageType}
                                                                                        placeholder={t('product.create.form.tip-package-type')}
                                                                                        onChange={(v) => {setFormInputs({...formInputs, packageType: v })}}
                                                                                        />
                                                                            </Tooltip>

                                                                            </Form.Item>
                                                                        </Layout.Col>
                                                                    </Layout.Row>

                                                                    <Layout.Row type="flex">
                                                                        <Layout.Col span="24" offset="1" xs="20" sm="20" lg="24">
                                                                            <Form.Item style={{display: 'inline-flex'}} label={t('product.create.form.quantity-per-package') } prop="quantityPerPackage">
                                                                                <div style={{display: 'inline-flex'}}>
                                                                                    <Input
                                                                                        className='input-customA'
                                                                                        value={formInputs.quantityPerPackage}
                                                                                        onChange={(v) => {setFormInputs({...formInputs, quantityPerPackage: v })}}
                                                                                    />
                                                                                    <label className="label-custom p-l-sm">{formInputs.measurement}s/package</label>
                                                                                </div>
                                                                            </Form.Item>
                                                                        </Layout.Col>
                                                                    </Layout.Row>

                                                                    <Layout.Row type="flex">
                                                                        <Layout.Col span="24" offset="1" xs="20" sm="20" lg="24">
                                                                            <Form.Item style={{display: 'inline-flex'}} label={t('product.create.form.package-weight') } prop="packageWeight">
                                                                                <div style={{display: 'inline-flex'}}>
                                                                                    <Input
                                                                                        value={formInputs.packageWeight}
                                                                                        onChange={(v) => {setFormInputs({...formInputs, packageWeight: v })}}
                                                                                        />
                                                                                    <label className="label-custom p-l-sm">Kg</label>
                                                                                </div>
                                                                            </Form.Item>
                                                                        </Layout.Col>
                                                                    </Layout.Row>

                                                                    <Layout.Row type="flex">
                                                                    <Layout.Col span="24" offset="1" xs="20" sm="20" lg="24">
                                                                            <Form.Item style={{display: 'inline-flex'}} label={t('product.create.form.package-dimensions') } prop="packageDimensions1">
                                                                            <Form.Item style={{display: 'inline-flex'}} prop="packageDimensions2">
                                                                            <Form.Item style={{display: 'inline-flex'}} prop="packageDimensions3">
                                                                                <div style={{display: 'inline-flex'}}>
                                                                                    <Input
                                                                                        value={formInputs.packageDimensions1}
                                                                                        style={{'width' : '60px'}}
                                                                                        onChange={(v) => {setFormInputs({...formInputs, packageDimensions1: v })}}
                                                                                    />
                                                                                    &nbsp;x&nbsp;

                                                                                        <Input
                                                                                            value={formInputs.packageDimensions2}
                                                                                            style={{'width' : '60px'}}
                                                                                            onChange={(v) => {setFormInputs({...formInputs, packageDimensions2: v })}}
                                                                                        />


                                                                                    &nbsp;x&nbsp;

                                                                                        <Input
                                                                                            value={formInputs.packageDimensions3}
                                                                                            style={{'width' : '60px'}}
                                                                                            onChange={(v) => {setFormInputs({...formInputs, packageDimensions3: v })}}
                                                                                        />
                                                                                    <label className="label-custom p-l-sm">mm</label>
                                                                                </div>
                                                                            </Form.Item>
                                                                            </Form.Item>
                                                                            </Form.Item>
                                                                        </Layout.Col>
                                                                    </Layout.Row>


                                                                </Layout.Col>
                                                        </Layout.Row>


                                                        <Layout.Row type="flex">
                                                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="12">
                                                                    <Form.Item label={t('product.create.form.add-keywords')} prop="tags">
                                                                        <Input
                                                                            value={formInputs.tempTag}
                                                                            onChange={(v) => {setFormInputs({...formInputs, tempTag: v })}}
                                                                        />
                                                                    </Form.Item>
                                                                </Layout.Col>
                                                                <Layout.Col tag="form-button-inverse" span="24" offset="1" xs="20" sm="20" lg="6">
                                                                    <Form.Item>
                                                                        <br/>&nbsp;&nbsp;&nbsp;
                                                                        <Button disabled={(formInputs.tempTag.trim() === '' || formInputs.tags.length >= 12)} type="primary" onClick={addKeyword}>
                                                                            {t('product.create.form.button-add-keywords')}
                                                                        </Button>
                                                                    </Form.Item>
                                                                </Layout.Col>
                                                        </Layout.Row>
                                                        <Layout.Row type="flex">
                                                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="12">
                                                                    <Card className="box-card">
                                                                    {
                                                                        formInputs.tags.map((tag, index) => {
                                                                        return (
                                                                                <Tag
                                                                                    key={Math.random()}
                                                                                    closable={true}
                                                                                    closeTransition={false}
                                                                                    type="primary"
                                                                                    onClose={() => removeTag(tag)}
                                                                                >
                                                                                        {tag}
                                                                                </Tag>

                                                                        )
                                                                        })
                                                                    }
                                                                    </Card>
                                                                </Layout.Col>
                                                        </Layout.Row>
                                                    <Layout.Row type="flex">
                                                        <Layout.Col span="20" offset="1" xs="20" sm="20" lg="18">
                                                            &nbsp;
                                                        </Layout.Col>
                                                    </Layout.Row>
                                                    <Layout.Row type="flex">
                                                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="20">
                                                                    <label className="label-custom"> {t('product.create.form.photos')} </label>
                                                                </Layout.Col>
                                                    </Layout.Row>

                                                    <Layout.Row type="flex">
                                                                <Layout.Col span="24" offset="1" xs="24" sm="24" lg="24">
                                                                    <div className="photos-div" style={{display: 'inline-flex'}}>
                                                                    {
                                                                        photos.map((item, index) => {
                                                                            return(
                                                                                <ThumbPreview
                                                                                    key={index} height={100} width={100} alt={item.name} src={item.src}
                                                                                    handleImageDelete={()=> handleImageDelete(index, 1)}
                                                                                />

                                                                            )
                                                                        })

                                                                    }
                                                                    {
                                                                        photos.length < 7
                                                                        ?
                                                                            <Button type="primary" onClick={() =>setPhotoDialog(true)}>
                                                                            ADD +
                                                                            </Button>
                                                                        :
                                                                            <></>
                                                                    }
                                                                    </div>
                                                                </Layout.Col>
                                                    </Layout.Row>

                                                    <Layout.Row type="flex">
                                                        <Layout.Col span="20" offset="1" xs="20" sm="20" lg="18">
                                                            &nbsp;
                                                        </Layout.Col>
                                                    </Layout.Row>

                                                    <Layout.Row type="flex" justify="end" >
                                                        <Layout.Col tag="form-button-inverse" pull="1" xs="20" sm="20" lg="20">
                                                            <Form.Item>
                                                                <Button type="primary" onClick={prevStep}>
                                                                        {t('product.create.form.button-prev')}
                                                                </Button>
                                                            </Form.Item>
                                                        </Layout.Col>
                                                        <Layout.Col tag="form-button" pull="1" xs="20" sm="20" lg="20">
                                                            <Form.Item>
                                                                <Button type="primary" onClick={nextStep}>
                                                                        {t('my-store.create.form.button-next')}
                                                                </Button>
                                                            </Form.Item>
                                                        </Layout.Col>
                                                    </Layout.Row>
                                                </div>
                                                :   <></>
                                            }
                                        </Form>

                                        <Form ref={form2} model={formInputs} className="en-US" rules={rules2} label-position="top">
                                            {
                                                currentStep === 2 ?
                                                    <div>
                                                    <Layout.Row type="flex">
                                                            <Layout.Col span="24" offset="1" xs="20" sm="20" lg="8">
                                                                <Form.Item label={t('product.create.form.brand')} prop="brand">
                                                                    <Input
                                                                        value={formInputs.brand}
                                                                        onChange={(v) => {setFormInputs({...formInputs, brand: v })}}
                                                                        />
                                                                </Form.Item>
                                                            </Layout.Col>
                                                            <Layout.Col span="24" offset="1" xs="20" sm="20" lg="8">
                                                                <Form.Item label={t('product.create.form.int-ext')} prop="intExt">
                                                                    <Input
                                                                        value={formInputs.intExt}
                                                                        onChange={(v) => {setFormInputs({...formInputs, intExt: v })}}
                                                                        />
                                                                </Form.Item>
                                                            </Layout.Col>
                                                    </Layout.Row>
                                                    <Layout.Row type="flex">
                                                            <Layout.Col span="24" offset="1" xs="20" sm="20" lg="8">
                                                                <Form.Item label={t('product.create.form.size')} prop="size">
                                                                    <Input
                                                                        value={formInputs.size}
                                                                        onChange={(v) => {setFormInputs({...formInputs, size: v })}}
                                                                        />
                                                                </Form.Item>
                                                            </Layout.Col>
                                                            <Layout.Col span="24" offset="1" xs="20" sm="20" lg="8">
                                                                <Form.Item label={t('product.create.form.special-functions')} prop="specialFunctions">
                                                                    <Input
                                                                        value={formInputs.specialFunctions}
                                                                        onChange={(v) => {setFormInputs({...formInputs, specialFunctions: v })}}
                                                                        />
                                                                </Form.Item>
                                                            </Layout.Col>
                                                    </Layout.Row>
                                                    <Layout.Row type="flex">
                                                            <Layout.Col span="24" offset="1" xs="20" sm="20" lg="8">
                                                                <Form.Item label={t('product.create.form.color')} prop="color">
                                                                    <Input
                                                                        value={formInputs.color}
                                                                        onChange={(v) => {setFormInputs({...formInputs, color: v })}}
                                                                        />
                                                                </Form.Item>
                                                            </Layout.Col>
                                                            <Layout.Col span="24" offset="1" xs="20" sm="20" lg="8">
                                                                <Form.Item label={t('product.create.form.material')} prop="intExtmaterial">
                                                                    <Input
                                                                        value={formInputs.material}
                                                                        onChange={(v) => {setFormInputs({...formInputs, material: v })}}
                                                                        />
                                                                </Form.Item>
                                                            </Layout.Col>
                                                    </Layout.Row>
                                                    <Layout.Row type="flex">
                                                            <Layout.Col span="24" offset="1" xs="20" sm="20" lg="8">
                                                                <Form.Item label={t('product.create.form.titles')} prop="titles">
                                                                    <Input
                                                                        value={formInputs.titles}
                                                                        onChange={(v) => {setFormInputs({...formInputs, titles: v })}}
                                                                        />
                                                                </Form.Item>
                                                            </Layout.Col>
                                                            <Layout.Col span="24" offset="1" xs="20" sm="20" lg="8">
                                                                <Form.Item label={t('product.create.form.titles2')} prop="titles2">
                                                                    <Input
                                                                        value={formInputs.titles2}
                                                                        onChange={(v) => {setFormInputs({...formInputs, titles2: v })}}
                                                                        />
                                                                </Form.Item>
                                                            </Layout.Col>
                                                    </Layout.Row>
                                                    <Layout.Row type="flex">
                                                            <Layout.Col span="24" offset="1" xs="20" sm="20" lg="8">
                                                                <Form.Item label={t('product.create.form.voltage')} prop="voltage">
                                                                    <Input
                                                                        value={formInputs.voltage}
                                                                        onChange={(v) => {setFormInputs({...formInputs, voltage: v })}}
                                                                        />
                                                                </Form.Item>
                                                            </Layout.Col>
                                                            <Layout.Col span="24" offset="1" xs="20" sm="20" lg="8">
                                                                <Form.Item label={t('product.create.form.power')} prop="power">
                                                                    <Input
                                                                        value={formInputs.power}
                                                                        onChange={(v) => {setFormInputs({...formInputs, power: v })}}
                                                                        />
                                                                </Form.Item>
                                                            </Layout.Col>
                                                    </Layout.Row>
                                                    <Layout.Row type="flex">
                                                            <Layout.Col span="24" offset="1" xs="20" sm="20" lg="8">
                                                                <Form.Item label={t('product.create.form.protection')} prop="protection">
                                                                    <Input
                                                                        value={formInputs.protection}
                                                                        onChange={(v) => {setFormInputs({...formInputs, protection: v })}}
                                                                        />
                                                                </Form.Item>
                                                            </Layout.Col>
                                                            <Layout.Col span="24" offset="1" xs="20" sm="20" lg="8">
                                                                <Form.Item label={t('product.create.form.life-time')} prop="lifeTime">
                                                                    <Input
                                                                        value={formInputs.lifeTime}
                                                                        onChange={(v) => {setFormInputs({...formInputs, lifeTime: v })}}
                                                                        />
                                                                </Form.Item>
                                                            </Layout.Col>
                                                    </Layout.Row>

                                                    <Layout.Row type="flex">
                                                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="20">
                                                                    <label className="label-custom"> {t('product.create.form.photos-additional')}</label>
                                                                </Layout.Col>
                                                    </Layout.Row>

                                                    <Layout.Row type="flex">
                                                                <Layout.Col span="24" offset="1" xs="24" sm="24" lg="24">
                                                                    <div className="photos-div" style={{display: 'inline-flex'}}>
                                                                    {

                                                                        additionalPhotos.map((item, index) => {
                                                                            return(
                                                                                <ThumbPreview
                                                                                    key={index} height={100} width={100} alt={item.name} src={item.src}
                                                                                    handleImageDelete={()=> handleImageDelete(index, 2)}
                                                                                />

                                                                            )
                                                                        })

                                                                    }
                                                                        {
                                                                            additionalPhotos.length < 6
                                                                            ?
                                                                                <Button type="primary" onClick={() =>setAdditionalPhotosDialog(true)}>
                                                                                ADD +
                                                                                </Button>
                                                                            :
                                                                                <></>
                                                                        }

                                                                    </div>
                                                                </Layout.Col>
                                                    </Layout.Row>

                                                    <Layout.Row type="flex" justify="end" >
                                                        <Layout.Col tag="form-button-inverse" pull="1" xs="20" sm="20" lg="20">
                                                            <Form.Item>
                                                                <Button type="primary" onClick={prevStep}>
                                                                        {t('product.create.form.button-prev')}
                                                                </Button>
                                                            </Form.Item>
                                                        </Layout.Col>
                                                        <Layout.Col tag="form-button" pull="1" xs="20" sm="20" lg="20">
                                                            <Form.Item>
                                                                <Button type="primary" disabled={formInputs.subCategoryId == '' ? true: false} onClick={nextStep}>
                                                                        {t('my-store.create.form.button-next')}
                                                                </Button>
                                                            </Form.Item>
                                                        </Layout.Col>
                                                    </Layout.Row>
                                                    </div>
                                                :
                                                    <></>

                                            }
                                        </Form>

                                        <Form ref={form3} model={formInputs} className="en-US" rules={rules3} label-position="top">
                                            {
                                                currentStep === 3 ?
                                                    <div>
                                                        <Layout.Row type="flex">
                                                            <Layout.Col span="24" offset="1" xs="20" sm="20" lg="22">
                                                                <h3>{t('product.create.form.sub-title3')}</h3>
                                                            </Layout.Col>
                                                        </Layout.Row>
                                                        <Layout.Row type="flex">
                                                            <Layout.Col span="24" offset="1" xs="20" sm="20" lg="22">
                                                                <label className="label-custom"> {t('product.create.form.sub-title3-1')}</label>
                                                            </Layout.Col>
                                                        </Layout.Row>
                                                        <Layout.Row type="flex">
                                                            <Layout.Col span="24" offset="1" xs="20" sm="20" lg="22">
                                                                <Form.Item label={t('product.create.form.brand')} prop="brand">

                                                                    <JoditEditor
                                                                        ref={editor}
                                                                        value={content}
                                                                        config={config}
                                                                        //beforePaste={handleBeforePaste}
                                                                        tabIndex={1} // tabIndex of textarea
                                                                        onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                                                                        onChange={newContent => {}}
                                                                    />

                                                                </Form.Item>
                                                            </Layout.Col>
                                                        </Layout.Row>
                                                    <Layout.Row type="flex">
                                                                <Layout.Col span="24" offset="1" xs="20" sm="20" lg="22">
                                                                    <label className="label-custom"> {t('product.create.form.photos-tech')}</label>
                                                                </Layout.Col>
                                                    </Layout.Row>

                                                    <Layout.Row type="flex">
                                                                <Layout.Col span="24" offset="1" xs="24" sm="24" lg="24">
                                                                    <div className="photos-div" style={{display: 'inline-flex'}}>
                                                                    {
                                                                        photosTechnical.map((item, index) => {
                                                                            return(
                                                                                <ThumbPreview
                                                                                    key={index} height={100} width={100} alt={item.name} src={item.src}
                                                                                    handleImageDelete={()=> handleImageDelete(index, 3)}
                                                                                />

                                                                            )
                                                                        })

                                                                    }
                                                                        {
                                                                            photosTechnical.length < 4
                                                                            ?
                                                                                <Button type="primary" onClick={() =>setPhotoTechDialog(true)}>
                                                                                ADD +
                                                                                </Button>
                                                                            :
                                                                                <></>
                                                                        }

                                                                    </div>
                                                                </Layout.Col>
                                                    </Layout.Row>

                                                    <Layout.Row type="flex">
                                                        <Layout.Col span="20" offset="1" xs="20" sm="20" lg="18">
                                                            &nbsp;
                                                        </Layout.Col>
                                                    </Layout.Row>
                                                        <Layout.Row type="flex" justify="end" >
                                                            <Layout.Col tag="form-button-inverse" pull="1" xs="20" sm="20" lg="20">
                                                                <Form.Item>
                                                                    <Button type="primary" onClick={prevStep}>
                                                                            {t('product.create.form.button-prev')}
                                                                    </Button>
                                                                </Form.Item>
                                                            </Layout.Col>
                                                            <Layout.Col tag="form-button" pull="1" xs="20" sm="20" lg="20">
                                                                <Form.Item>
                                                                    <Button type="primary" onClick={handleSaveMyProduct}>
                                                                            {t('product.create.form.button-finish')}
                                                                    </Button>
                                                                </Form.Item>
                                                            </Layout.Col>
                                                        </Layout.Row>
                                                    </div>
                                                :
                                                    <></>
                                            }
                                        </Form>

                                    </Card>
                            </Layout.Col>
                </Layout.Row>
            </div>
        </div>
    )
}

export default ProductWizard;